import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter09 from '../common/src/assets/image/charity/specter/kapitola_09_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Deviata kapitola: Ekonomická pasca komunizmu | Ako duch komunizmu vládne nášmu svetu"
           description="Deviata kapitola: Ekonomická pasca komunizmu"
           image={imageChapter09}/>
      <h1>Deviata kapitola: Ekonomická pasca komunizmu</h1>
      <p>&nbsp;</p>
      <h2>Úvod</h2>
      <p>&nbsp;</p>
      <p>Vplyv komunizmu je prítomný v každom sektore nášho súčasného ekonomického systému. Trend neustále sa rozširujúcej vlády sa stal normou, pričom prakticky každá krajina na Zemi sa odkláňa od klasických princípov voľného trhu a tiahne ku komunistickej alebo socialistickej ekonomike.</p>
      <p>Ak sa pozrieme na krajiny, ktoré po páde Sovietskeho zväzu opustili komunizmus alebo socialistický ekonomický model, mohli by sme si myslieť, že ciele ducha komunizmu neboli naplnené. Skutočnosť však nie je taká jednoduchá. Metódy ducha komunizmu nenasledujú jeden konkrétny vzorec. Na dosiahnutie väčšieho cieľa môže opustiť určité formy, a namiesto toho prijme iné, tak aby vyhovovali historickej či spoločenskej situácii. Platí to obzvlášť v ekonomickej sfére.</p>
      <p>Pred viac ako 150 rokmi Karl Marx v knihe <em>Kapitál</em> obhajoval zrušenie súkromného vlastníctva a jeho nahradenie vlastníctvom kolektívnym. Totalitné komunistické štáty sa usilovali dosiahnuť tento cieľ priamo, za použitia teroru, násilia a masových vrážd. Hneď ako však táto otvorená komunistická doktrína stratila svoje čaro, stúpenci ľavice v demokratických krajinách navrhli nenásilné formy. Nespočetné vetvy socializmu a komunizmu, ktoré v priebehu rokov vytvorili a zaviedli, nie je možné ľahko klasifikovať.</p>
      <p>Okrem toho, že ekonomická stratégia komunizmu obmedzuje základné právo na súkromné vlastníctvo a podnikanie, podporuje korupciu a prispieva k erózii tradičnej kultúry. Aby si štáty po celom svete uchovali svoju prosperitu, spôsob života a morálne základy, musia pochopiť komunistickú sabotáž v ekonomickej sfére a prijať opatrenia proti nej.</p>
      <p>&nbsp;</p>
      <h2>Štátne vlastníctvo a plánovaná ekonomika: systémy otroctva</h2>
      <p>Nebesá stvorili človeka, obdarili ho múdrosťou a silou a ustanovili, že bude vo svojom živote žať odmenu za svoju prácu – a tak bude môcť získať dostatok prostriedkov na svoju obživu. Americká Deklarácia nezávislosti hovorí: „Pokladáme za samozrejmé pravdy, že všetci ľudia sú stvorení seberovní, že sú obdarení svojím Stvoriteľom určitými neodňateľnými právami, že medzi tieto práva patrí právo na život, slobodu a osobné šťastie.“<a href="#_edn1" id="_ednref1">[1]</a> Tieto práva prirodzene zahŕňajú právo vlastniť a prideľovať majetok.</p>
      <p>Oproti tomu Marx a Engels v <em>Komunistickom manifeste </em>uvádzajú: „Komunistická teória sa dá zhrnúť jednou vetou: zrušenie súkromného vlastníctva.“<a href="#_edn2" id="_ednref2">[2]</a> Tým odkazujú na kolektívne vlastníctvo, ktoré je v plánovanej ekonomike povinné. V komunistických plánovaných ekonomikách sú výrobné prostriedky riadené priamo štátom. Podstata tohto systému porušuje nebeské princípy, je v rozpore s ľudskou prirodzenosťou a v konečnom dôsledku predstavuje formu otroctva.</p>
      <h3>a) Štátne vlastníctvo: totalitné jarmo</h3>
      <p>Fred Schwarz, americký priekopník antikomunizmu, rozpráva vo svojej knihe <em>Komunistom môžete veriť ... že sú komunistami</em> (You Can Trust the Communists ... to Be Communists) vtip o reportérovi, ktorý navštívi najprv sovietsku a potom americkú automobilku:</p>
      <p>„Komu patrí táto továreň?”</p>
      <p>„Nám,” odpovedajú robotníci.</p>
      <p>„Komu patrí pozemok, na ktorej je postavená?”</p>
      <p>„Nám.”</p>
      <p>„Komu patria výrobky továrne, keď sú vyrobené?”</p>
      <p>„Nám.”</p>
      <p>Vonku v rohu veľkého parkoviska stoja tri otlčené vozy. Návštevník sa spýta: „Komu patria tamtie autá?”</p>
      <p>Odpoveď znie: „Patria nám, ale jedno z nich používa riaditeľ továrne, druhé používa politický komisár a tretie používa tajná polícia.”</p>
      <p>Ten istý reportér potom navštívi továreň v Amerike a opäť sa pýta robotníkov: „Komu patrí táto továreň?”</p>
      <p>„Henrymu Fordovi,” odpovedajú robotníci.</p>
      <p>„Komu patrí pozemok, na ktorom je postavená?”</p>
      <p>"Henrymu Fordovi.”</p>
      <p>„Komu patria výrobky továrne, keď sú vyrobené?”</p>
      <p>„Henrymu Fordovi.”</p>
      <p>Pred továrňou stojí veľké parkovisko plné moderných amerických áut všakovakých značiek a typov. Muž sa spýta: „Komu patrí všetky tie autá?”</p>
      <p>„Tie sú naše,” odpovedajú robotníci.<a href="#_edn3" id="_ednref3">[3]</a></p>
      <p>Tento príbeh ukazuje dôsledky a rozdiely medzi systémami súkromného a štátneho vlastníctva. V systéme štátneho vlastníctva sú zdroje a zisky z práce znárodnené. Mechanizmy, ktoré poháňajú individuálne nadšenie, snahu a inovácie, sú preč, rovnako ako zmysel pre zodpovednosť vyplývajúci z práva na osobné vlastníctvo. Štátne vlastníctvo podľa názvu znamená, že bohatstvo krajiny zdieľajú všetci občania, ale v praxi ide o to, že zdroje si zmonopolizuje privilegovaná trieda, ktorá sa stará v prvom rade o svoj prospech.</p>
      <p>Hlavným faktorom ekonomického rastu sú ľudia. Štátne vlastníctvo potláča elán a motiváciu ľudí, aby boli produktívni. Podkopáva morálku, podporuje neefektívnosť a spôsobuje nadmernú ponuku či naopak, závažný nedostatok tovaru. V sovietskych kolchozoch, ľudových komúnach v Číne, či pri neúspešnej kolektivizácii v Kambodži a v Severnej Kórei priniesol systém štátneho vlastníctva hlad všade, kam vstúpil. Napríklad umelo vyvolaný hladomor medzi rokmi 1959 a 1961 v Číne priniesol smrť desiatkam miliónov ľudí.</p>
      <p>Ľudia v sebe majú láskavosť, ale aj zlo. Súkromné ​​vlastníctvo umožňuje človeku rozvíjať poctivosť a podporuje pracovitosť a šetrnosť. Kolektívne vlastníctvo naopak povzbudzuje zlé črty ľudskej povahy a podporuje závisť a lenivosť.</p>
      <p>Rakúsky ekonóm a filozof Friedrich Hayek uviedol, že rast civilizácie sa opiera o spoločenské tradície, ktoré považujú súkromný majetok za ústredný princíp. Tieto tradície splodili moderný systém obchodu a s ním spojený hospodársky rast. Jedná sa o organický poriadok, ktorý pre svoju činnosť nevyžaduje riadenie vlády, sám seba vytvára a riadi. Komunistické a socialistické hnutia sa však snažia tento spontánne vznikajúci a fungujúci poriadok kontrolovať – ​​čo Hayek nazval ich &nbsp;„osudnou domýšľavosťou“.<a href="#_edn4" id="_ednref4">[4]</a></p>
      <p>Ak sú súkromné vlastníctvo a sloboda neoddeliteľné, potom sa ten istý princíp vzťahuje na štátne vlastníctvo, ktoré ide ruka v ruke s diktatúrou a potláčaním. Systém štátneho vlastníctva znárodňuje zdroje, znižuje ekonomickú produktivitu a mení ľudí na služobníkov a otrokov. Všetci ľudia musia poslúchať príkazy ústrednej strany a akékoľvek myšlienky a hlasy, ktoré nie sú v súlade s režimom, môžu byť umlčané. Ľudia sú potom proti štátnej intervencii bezmocní.</p>
      <p>Odstránenie súkromného vlastníctva a vytvorenie štátneho vlastníctva tak vo výsledku nevyhnutne vedie k totalite. Kolektivizmus je ako chápadlo, pripevnené na krk človeka totalitným štátom. Ľudia sú pripravení o svoju slobodu – vrátane slobody byť poctivý – a každý je nútený nasledovať morálne príkazy komunistického režimu.</p>
      <p>Ak sa moc privatizuje a bohatstvo kolektivizuje, ľudstvo čaká katastrofa.</p>
      <h3>b) Plánovaná ekonomika: predurčená k zlyhaniu</h3>
      <p>V rámci plánovanej ekonomiky je výroba, rozdeľovanie zdrojov a distribúcia produktov v celej spoločnosti založená na pláne stanovenom štátom. To je úplne odlišné od ekonomiky založenej na ponuke a dopyte v rámci voľného trhu.</p>
      <p>Plánovaná ekonomika má prirodzené a zjavné chyby. Po prvé, vyžaduje si zber veľkého množstva dát, aby bolo možné urobiť rozumné opatrenia týkajúce sa výroby. Pre krajinu, a najmä pre modernú krajinu s veľkou populáciou, je množstvo relevantných informácií nepredstaviteľne veľké a nie je možné ich spracovať. Úrad pre oceňovanie komodít v bývalom Sovietskom zväze musel napríklad stanoviť ceny u 24 miliónov rôznych druhov tovaru.<a href="#_edn5" id="_ednref5">[5]</a></p>
      <p>Komplexnosť a premenlivosť spoločnosti a ľudí nemožno riešiť jednotne plánovanou ekonomikou. Aj s použitím moderných dátových systémov a umelej inteligencie nemožno ľudské myšlienky zohľadniť ako premenné na vstupe, takže takýto systém bude vždy neúplný.</p>
      <p>Ekonóm Ludwig von Mises vo svojom článku „Ekonomický výpočet v socialistickom spoločenstve” hovoril o vzťahu medzi socializmom a trhom.<a href="#_edn6" id="_ednref6">[6]</a> Poznamenáva tu, že bez reálneho trhu nebude socialistická spoločnosť schopná vykonávať rozumné ekonomické výpočty. Rozdelenie zdrojov teda nemožno logicky zracionalizovať a plánovaná ekonomika zlyhá.</p>
      <p>Okrem toho ekonomické plánovanie vyžaduje nútenú kontrolu zdrojov zo strany štátu. To nakoniec vyžaduje absolútnu moc, kvóty a príkazy. Keď sa požiadavky reálneho sveta nezhodujú so štátnym plánovaním, štátna moc zadusí prirodzené ekonomické trendy, čo bude mať za následok masové, nespravodlivé prerozdeľovanie kapitálu a všetky s tým súvisiace problémy. Plánované hospodárstvo používa obmedzenú moc a múdrosť vlády, ktorá sa chce hrať na Boha. Tento jej pokus je odsúdený na neúspech.</p>
      <p>Ekonomika moci je predovšetkým podriadená politike, a&nbsp;nie skutočným potrebám národa. Plánovaná ekonomika a autoritárska politika sú neoddeliteľné. Vzhľadom k tomu, že v národnom plánovaní sa nevyhnutne vyskytnú chyby, keď sa objavia problémy, budú plány spochybňované vo vnútri vlády, ako aj zvonku. Tí, ktorí sú pri moci, budú mať pocit, že ich autorita je napádaná, a budú na spochybňovanie odpovedať politickým tlakom a čistkami. Napríklad Mao Ce-tung ignoroval zákony ekonomiky a jeho vynútený Veľký skok vpred skončil trojročným hladomorom, ktorý zapríčinil desiatky miliónov úmrtí. To viedlo k tomu, že Mao Ce-tung musel čeliť spochybňovaniu jeho pozície v ČKS. A práve to bolo kľúčovým dôvodom toho, prečo neskôr začal kultúrnu revolúciu.</p>
      <p>Katastrofálne dopady plánovanej ekonomiky a kolektívneho vlastníctva sa plne prejavili v súčasných podmienkach čínskych štátom vlastnených podnikov. V uplynulých rokoch veľké množstvo týchto firiem zastavilo alebo spomalilo výrobu, každý rok utrpeli straty alebo sa stali insolventnými. Ohľadom udržania chodu sa spoliehajú na vládne dotácie a bankové úvery. V podstate sa stávajú parazitmi národného hospodárstva a mnohé z nich sú všeobecne známe ako „zombie podniky“.<a href="#_edn7" id="_ednref7">[7]</a> Medzi 150 000 podnikmi, ktoré v Číne vlastní štát, s výnimkou štátnych monopolov v lukratívnych odvetviach ropného priemyslu a telekomunikácií, vykazujú ostatné štátne podniky minimálne zisky a trpia vážnymi stratami. Koncom roka 2015 predstavovali ich celkové aktíva 176 % HDP, dlh 127 % a zisk iba 3,4 %.<a href="#_edn8" id="_ednref8">[8]</a> Niektorí ekonómovia sú toho názoru, že čínska ekonomika sa v podstate stala rukojemníkom týchto zombie podnikov. Čínska ekonomika sa po dlhé roky opierala o lacnú výrobu, umožnenú extrémnym vykorisťovaním robotníkov, ktorí pracovali za minimálne mzdy. Takisto nebrala do úvahy životné prostredie.</p>
      <p>Plánovaná ekonomika zbavuje človeka slobody a zároveň núti štát, aby sa o neho postaral. Všetky aspekty života ľudí sa dostávajú pod kontrolu štátu, ktorý zamyká ľudí v neviditeľnom väzení, snaží sa potlačiť slobodnú vôľu a mení parametre ľudského života stanovené nebesami. Podstatou celého projektu je premeniť ľudí na otrokov a stroje. Je to ďalší prejav komunistickej vzbury proti Bohu a prirodzenému právu.</p>
      <h2>2. Západné krajiny: prax komunizmu pod iným názvom</h2>
      <p>V marxizme „zrušenie osobného vlastníctva“ znamená pre jednotlivca „zrušenie buržoáznej individuality, buržoáznej nezávislosti a buržoáznej slobody“. Pre spoločnosť to znamená, že „proletariát využije svoju politickú moc na to, aby postupne uchvátil buržoázii všetok kapitál, sústredil všetky výrobné nástroje v rukách štátu, t. j. proletariátu organizovaného ako vládnuca trieda“.<a href="#_edn9" id="_ednref9">[9]</a></p>
      <p>Mnohé ekonomické stratégie alebo štruktúry sa na povrchu nemusia javiť ako socialistické, hrajú však úlohu obmedzovania, oslabovania a upierania práva ľudí na súkromné ​​vlastníctvo. Iné stratégie oslabujú mechanizmy voľného podnikania, rozširujú právomoci vlády a vedú spoločnosť stále ďalej po ceste k socializmu. Použité metódy zahŕňajú vysoké zdanenie, štedrú sociálnu starostlivosť a agresívne zásahy štátu do hospodárstva.</p>
      <h3>a) Vysoká miera zdanenia a&nbsp;sociálneho zabezpečenia</h3>
      <p>Vysoké zdanenie je skrytým spôsobom, ako postupne vyradiť systém súkromného vlastníctva. Konečný dôsledok vysokých daní je rovnaký ako štátne vlastníctvo a „rovnostárstvo“ vnútené komunistickými režimami. Líšia sa len v tom, či je znárodnenie vykonané ešte pred výrobou, alebo až po nej.</p>
      <p>Na Západe je výroba riadená súkromne, ale tržby sú premenené na štátne aktíva prostredníctvom daní a plánov na prerozdeľovanie. Toto odoberanie bohatstva je uskutočňované pomocou demokracie a legislatívy, nie zabíjaním a násilím.</p>
      <p>Dôležitým rysom komunistických a socialistických ekonomík, ktorý môžeme vidieť v západných krajinách, je robustný systém sociálneho zabezpečenia používaný na to, aby postupne narušil morálku a slobodu ľudí. Hoci určitá vládna pomoc je rozumná – ako napríklad sociálne zabezpečenie pre obete nešťastia a&nbsp;nehôd – môže sa však stať vhodným nástrojom klamu. Pozitívne aspekty sociálneho zabezpečenia sa stanú výhovorkou pre navýšenie daní a vládnej kontroly. V tejto súvislosti už deštruktívny vplyv štedrej štátnej pomoci na ľudí, spoločnosť a morálne hodnoty, dosiahol už to, čo sa deje v otvorene komunistických ekonomikách, bez potreby násilnej revolúcie.</p>
      <p>Sociálne zabezpečenie vo vyspelých západných krajinách spotrebúva veľkú časť príjmov, ktoré pochádzajú z daní. Všetky sociálne dávky musia byť v konečnom dôsledku zaplatené ľuďmi, či už prostredníctvom daní či štátneho dlhu. Na udržanie takejto vládnej štedrosti neexistuje žiadna ďalšia metóda. V Spojených štátoch je viac ako polovica príjmov z daní použitá na sociálne zabezpečenie a zdravotnú starostlivosť. Viac ako 80 % týchto peňazí pochádza zo zdanenia príjmov fyzických osôb a sociálneho poistenia; 11 % pochádza z daní z príjmov firiem.<a href="#_edn10" id="_ednref10">[10]</a> Takéto masívne vládne výdavky začali iba v minulom storočí.</p>
      <p>V roku 1895 Najvyšší súd USA vyhlásil, že dane z príjmu sú protiústavné. Toto rozhodnutie pretrvalo až do roku 1913, kedy bol ratifikovaný 16. dodatok ústavy. Dáta z pätnástich štátov v roku 1900 ukazujú, že iba sedem z nich zaviedlo daň z príjmu. V čele stálo Taliansko s 10% zdanením. Austrália, Japonsko a Nový Zéland mali daň z príjmu zhruba 5 %.</p>
      <p>Na základe údajov z roku 2016 o 35 trhových ekonomikách, ktoré zverejnila Organizácia pre ekonomickú spoluprácu a rozvoj (OECD), malo 27 krajín daň z príjmu vyššiu ako 30 %. Krajiny s dvoma najvyššími daňami z príjmov, 54 a 49,4 %, sa obe nachádzajú v Európe.<a href="#_edn11" id="_ednref11">[11]</a> Navyše, keď ide človek nakúpiť alebo sa najesť, v mnohých častiach Európy sa k tomu pridáva DPH, ktorá na niektorých miestach dosahuje až 20%. K tomu sa ešte pripočítavajú dane firiem a ďalšie dane, ktoré zvyšujú celkovú mieru daňového zaťaženia.</p>
      <p>Vysoké zdanenie nezaťažuje iba bohatých, ale aj osoby na spodných priečkach daňového rebríčka. Kým bohatí majú rôzne legálne prostriedky, ako sa pred daňami ochrániť, výhody sociálnej starostlivosti poskytovanej chudobným sa rozplynú, hneď ako ich príjem presiahne určitú úroveň. Po odpočítaní daní je ich príjem často menší než ten, ktorí získavali v rámci sociálnej podpory. Ľudia sú teda v praxi trestaní za to, že pracujú viac, a sú nabádaní k tomu, aby zostávali na sociálnej podpore.</p>
      <h4>Drahé sociálne zabezpečenie</h4>
      <p>V modernej spoločnosti sa systém vysokej miery sociálnej starostlivosti rozšíril, aby pokryl nezamestnanosť, lekársku starostlivosť, dôchodky, úrazy v zamestnaní, bývanie, vzdelanie, starostlivosť o deti atď. Siaha tak oveľa ďalej než tradičné koncepty pomoci pre ľudí v okamžitej núdzi.</p>
      <p>Správa nadácie Heritage Foundation ukázala, že v roku 2013 viac ako sto miliónov ľudí v Spojených štátoch, čo je viac ako jedna tretina populácie, dostala sociálne prídavky (okrem sociálneho zabezpečenia a lekárskej starostlivosti) v priemernej výške 9 000 dolárov na osobu.<a href="#_edn12" id="_ednref12">[12]</a> Podľa štatistík zhromaždených americkým úradom pre sčítanie ľudu okolo 14,8 % populácie žilo v roku 2013 pod hranicou chudoby, čo je približne rovnako veľa ako v roku 1967, niekoľko rokov potom, čo prezident Lyndon B. Johnson vyhlásil „bezpodmienečnú vojnu chudobe v Amerike “. Tieto údaje naznačujú, že ak enormne narastie sociálne zabezpečenie – tak ako sa to dialo za prezidenta Johnsona – percento ľudí žijúcich pod hranicou chudoby sa tým nezníži.</p>
      <p>V roku 2014, 50 rokov od doby, keď prezident Johnson vyhlásil vojnu chudobe, Američania zaplatili za sociálnu starostlivosť 2,2 bilióna dolárov. Napriek tomu štatistiky z amerického úradu pre sčítanie ľudu ukazujú, že stupeň chudoby sa za posledných 40 rokov nezmenil.<a href="#_edn13" id="_ednref13">[13]</a></p>
      <p>Stupeň chudoby sa navyše vypočítava z príjmu a nezohľadňuje rozličné benefity, ktoré príjemcovia sociálnej podpory získavajú, ako sú potravinové lístky, príspevky na bývanie a príspevky na vzdelávanie. Pred viac než storočím francúzsky filozof Alexis de Tocqueville povedal, že ak sa použije len hranica chudoby na prideľovanie sociálnej starostlivosti nerozlišujú medzi jednotlivcami, je ťažké poskytovať starostlivosť efektívne, pretože nie je možné zistiť, či ľudia, ktorí dostávajú podporu, skutočne trpia okolnosťami, za ktoré nemôžu, alebo či je ich nešťastie dôsledkom ich vlastného pričinenia.<a href="#_edn14" id="_ednref14">[14]</a></p>
      <p>Zámerné zaradenie veľkého počtu ľudí medzi „chudobnú“ časť populácie možno použiť ako vhodnú výhovorku pre rozšírenie sociálnej starostlivosti. Životná úroveň ľudí žijúcich v chudobe dnes je oveľa vyššia ako v 60. rokoch. V roku 1999 na základe vládnych dotazníkov 96 % rodičov v domácnostiach zasiahnutých chudobou povedalo, že ich deti nikdy neboli hladné kvôli tomu, že by si ich rodina nemohla dovoliť kúpiť jedlo. Takmer 50 % nízkopríjmových domácností tiež žilo v samostatných domoch a 40 % žilo v mestských domoch. Len 9 % žilo v mobilnom dome. 80 % malo klimatizáciu a dve pätiny širokouhlú LCD televíziu. Tri štvrtiny týchto nízkopríjmových domácností mali auto.<a href="#_edn15" id="_ednref15">[15]</a></p>
      <p>Aj tak sú však výhody poskytované americkou vládou v porovnaní s členmi OECD podpriemerné. Väčšina ľudí žijúcich v severských krajinách a iných západných európskych krajinách si užíva oveľa väčšie miery sociálneho zabezpečenia než Američania. Napríklad v Dánsku si aj najbohatší občania užívajú sieť sociálneho zabezpečenia „od kolísky po hrob“, ktoré zahŕňa bezplatnú lekársku starostlivosť, univerzitné vzdelanie a ďalšie štedré výhody. Švédom je umožnené mať 480 dní platenej rodičovskej dovolenky, ak sa im narodí dieťa alebo si dieťa adoptujú. Predtým, než ich krajina ekonomicky skolabovala, si Gréci užívali každoročný štrnásty plat a odchod do dôchodku v 57 rokoch. Vláda minula 17,5 % svojho HDP na vyplácanie dôchodkov.</p>
      <p>Rozširovanie sociálnej starostlivosti z tradičnej úlohy pomoci v núdzi na dlhodobé výhody pre celú populáciu je v skutočnosti súčasťou cieľa nastolenia komunistickej ekonomiky.</p>
      <h4>Sociálne dávky, korupcia a triedne konflikty</h4>
      <p>Z ekonomického hľadiska je podstatou sociálnej starostlivosti vziať peniaze od niektorých ľudí a odovzdať ich hodnotu iným. Avšak je za prerozdelenie bohatstva zodpovedná vláda, často bez toho, aby za to žiadala niečo naspäť. Tým sa stiera dávna múdrosť, že človek musí pracovať, aby niečo získal. Strata tohto morálneho princípu je zrejmá najmä v severnej Európe.</p>
      <p>Švédsky výskumník Nima Sanandaji to preukázal použitím dát z Prieskumu celosvetových hodnôt (World Value Survey). Začiatkom 80. rokov 20. storočia súhlasilo 82 % Švédov s vyhlásením, že „dostávať od vlády výhody, ktoré si nezaslúžite, je zlé“. V&nbsp;prieskume v rokoch 2010 – 2014 súhlasilo s týmto vyhlásením už iba 55 % Švédov.<a href="#_edn16" id="_ednref16">[16]</a></p>
      <p>V rámci štedrého systému sociálnej starostlivosti sú ľudia, ktorí pracujú tvrdo, odmeňovaní menej a tí, ktorí pracujú menej, dostávajú vďaka prídavkom viac. Toto postupne a nenápadne deformuje tradičnú morálku a tí, ktorí už vyrástli v tomto štedrom systéme sociálnej starostlivosti, strácajú pracovitosť, nezávislosť, zodpovednosť a usilovnosť svojich predkov. Systém berú ako samozrejmosť a sociálnu starostlivosť považujú za základné ľudské právo. Vyvinuli si zvyk spoliehať sa na vládu a dokonca ju berú ako záruku neustálej pomoci. Spoločenské hodnoty sa tak takmer nenávratne zmenili.</p>
      <p>Vysoká miera vládnej sociálnej starostlivosti tiež upiera význam tradičným charitám a ochudobňuje darcov o príležitosť vykonať dobré veci a príjemcov o šancu cítiť vďačnosť. V tradičnej spoločnosti charity fungovali na báze vlastnej voľby človeka, a tak priamo pomáhali tým menej šťastným, prípadne pôsobili formou obdarovania charitatívnych organizácií, ako sú napríklad cirkvi. Boli tu definovaní darcovia aj príjemcovia a dostať pomoc bola výsada a nie právo. Príjemcovia cítili vďačnosť za láskavosť darcov a boli motivovaní k tomu, aby použili tieto dary na zlepšenie svojej životnej situácie svojim vlastným úsilím. Tí, ktorí dostali dar a zmenili vďaka tomu svoj život, mali sklon túto priazeň vrátiť, hneď ako sa druhí stretli s podobnými ťažkosťami, ktorým kedysi čelili oni sami.</p>
      <p>Tocqueville veril, že charita spája dve cnosti, štedrosť a vďačnosť, ktoré na seba vzájomne pôsobia, aby zlepšili spoločnosť a vytvárali pozitívny morálny vplyv. Rovnako tak vzťah medzi darcami a príjemcami pôsobil na zmiernenie konfliktov a rozporov medzi bohatými a chudobnými, keďže charitatívna činnosť jednotlivcov spájala členov rôznych ekonomických skupín.<a href="#_edn17" id="_ednref17">[17]</a></p>
      <p>Prebujnený systém modernej sociálnej starostlivosti odcudzuje darcu a príjemcu byrokratizovaním procesu charity. „ Darcovia“ dnešnej doby sú platcami daní, ktorí sú nútení vzdať sa svojho majetku, namiesto toho, aby ho zdieľali dobrovoľne. Na druhej strane aj príjemcovia tohto majetku nemajú žiadne spojenie so svojimi dobrodincami a necítia žiadnu vďačnosť za ich obeť.</p>
      <p>Tocqueville veril, že systém sociálnej starostlivosti zhoršuje konflikty medzi bohatými a chudobnými. Tým, že im je časť ich majetku vynúteným spôsobom zabavená, bohatí cítia odpor k triede prijímajúcej sociálnu starostlivosť. Tocqueville povedal, že aj chudobní sa budú cítiť nespokojní, ak budú ekonomickú pomoc považovať za samozrejmosť: „Jedna trieda sa pozerá na svet so strachom a averziou, zatiaľ čo tá druhá posudzuje svoje nešťastie so zúfalstvom a závisťou.“<a href="#_edn18" id="_ednref18">[18]</a></p>
      <p>Prílišná miera sociálnej starostlivosti sa tiež stáva prostriedkom, ktorý komunizmus používa na rozdúchanie závisti a politického konfliktu. Možno to pozorovať na ekonomickej kríze v Grécku. U vyššej triedy sa podľa gréckych úradníkov citovaných časopisom The Economist stali daňové úniky „národným športom“.<a href="#_edn19" id="_ednref19">[19]</a> Keďže príjmy z&nbsp;daní poklesli, grécka vláda sa snažila znížiť výdavky na sociálnu starostlivosť, ale stretla sa s tvrdým odporom zo strany občanov. Takže aby nehnevala svojich voličov, grécka vláda sa spoliehala na pôžičky, aby nimi kompenzovala vytrácajúci sa príjem z daní a udržala rovnakú úroveň sociálnej starostlivosti ako v iných európskych krajinách. Grécko nakoniec zvýšilo dane stredným a vyšším príjmovým skupinám, farmárom a obchodníkom.</p>
      <p>Martin Halla, Mario Lackner a Friedrich G. Schneider v empirickej štúdii z roku 2009 zhromaždili údaje, ktoré ukazujú, že sociálna starostlivosť v dlhodobom meradle odrádza ľudí od plnohodnotnej práce. Títo traja ekonómovia prišli na to, že dynamika štátnej sociálnej starostlivosti je nepriaznivá pre zdravý ekonomický základ krajiny.<a href="#_edn20" id="_ednref20">[20]</a></p>
      <h4>Kultúra chudoby</h4>
      <p>Sociálna starostlivosť by mala byť núdzovým prostriedkom, ako pomôcť tým, ktorí to naozaj potrebujú, a je efektívna v situáciách ako sú pracovné úrazy, epidémie, prírodné katastrofy a tak ďalej. Nemala by sa stať štandardnou formou živobytia, pretože nie je schopná vyriešiť dilemu chudoby.</p>
      <p>Rozširovanie meradiel, ktoré určujú, kto má nárok na štátnu pomoc, vytvára atmosféru negatívnej motivácie, ktorá povzbudzuje zneužívanie týchto výhod. Napríklad termín „znevýhodnenie“ sa neustále predefinováva, aby zahŕňal čoraz viac ľudí, ktorí budú mať nárok na štátnu podporu. Výsledkom je ekonomická nestabilita a prepad v spoločenskej morálke.</p>
      <p>V roku 2012 The New York Times publikovali článok s názvom „Ako finančne ťažiť z detskej negramotnosti“, v ktorom popisovali vplyv politiky sociálnej starostlivosti na nízkopríjmové rodiny žijúce v Apalačských vrchoch vo východnej časti Spojených štátov. Článok opisoval, ako sa chudobné rodiny vzdávajú možnosti poslať svoje deti do škôl, aby sa mohli uchádzať o sociálnu podporu. „Matky a otcovia sa boja, že keď sa dieťa naučí čítať, majú menšiu šancu splniť kritériá na získanie mesačného šeku za intelektuálne znevýhodnenie,“ píše sa v článku. „Mnoho ľudí, ktorí tu žijú v mobilných domoch, je chudobných a zúfalých, a mesačný šek 698 dolárov na dieťa vďaka programu v rámci doplnkového sociálneho zabezpečenia im poskytuje značnú pomoc – a tieto šeky možno poberať až do tej doby, kým nedovŕši dieťaťa 18 rokov.“<a href="#_edn21" id="_ednref21">[21]</a></p>
      <p>Tento program začal pred zhruba 40 rokmi s cieľom pomôcť rodinám, ktoré vychovávajú závažne fyzicky či mentálne postihnuté deti, kvôli čomu ich rodičia nemohli pracovať. Týkalo sa to približne jedného percenta chudobných detí. V roku 2012 bolo viac ako 55 % posudzovaných detí uznaných za duševne postihnuté, ale ich diagnóza nebola nijako spresnená. Naprieč Spojenými štátmi je teraz celkovo 1,2 milióna „duševne postihnutých“ detí, ktorým platitelia daní poskytujú každoročne deväť miliárd dolárov.<a href="#_edn22" id="_ednref22">[22]</a></p>
      <p>Ako je znázornené na príklade, sociálna starostlivosť a vady ľudskej povahy sa navzájom posilňujú v začarovanom kruhu. Aj napriek dobrým úmyslom tých, ktorí obhajujú a navrhujú politiku sociálnej starostlivosti, sú výsledky tejto politiky často neblahé, ako pre jednotlivcov, tak aj pre celú spoločnosť.</p>
      <p>Zneužívanie systému sociálnej starostlivosti nepostihuje len verejné finančné prostriedky, ale ovplyvňuje tiež budúcnosť detí, ktoré v tomto systémom vyrastajú. Prieskum uskutočnený v roku 2009 zistil, že dve tretiny ľudí, ktorí dostávali sociálne dávky ako deti, ich naďalej poberajú aj ako dospelí.<a href="#_edn23" id="_ednref23">[23]</a></p>
      <p>Podľa amerického ekonóma Williama A. Niskanena systém sociálnej starostlivosti vytvoril kultúru chudoby, ktorá posilňuje začarovaný kruh závislosti od štátnej pomoci, zvýšeného počtu mimomanželských detí, násilných zločinov, nezamestnanosti a potratov.</p>
      <p>Niskanenova analýza dát z celých Spojených štátov za rok 1992 odhaduje vplyvy, ktoré možno očakávať zo zvýšenej pomoci a poskytovania výhod rodinám so závislými deťmi o&nbsp;1 % priemerného osobného príjmu: počet príjemcov dávok narastie o 3 %; počet ľudí postihnutých chudobou sa zvýši o 0,8 %; počet detí, narodených slobodným matkám, vzrastie o 2,1 %; a počet nezamestnaných dospelých sa zvýši približne o 0,5 %. O viac ako 1 % tiež vzrastie počet potratov ako aj násilných zločinov.<a href="#_edn24" id="_ednref24">[24]</a> Niskanenove zistenia naznačujú, že robustný systém sociálnej starostlivosti rozvíja závislosť na systéme a odrádza od prevzatia osobnej zodpovednosti.</p>
      <p>Rozpad rodín značne prispieva ku kultúre chudoby. Pri skúmaní historickej a súčasnej chudoby medzi ľuďmi čiernej pleti ekonóm Walter E. Williams zistil, že v roku 1925 boli v meste New York v&nbsp;85 % černošských rodín prítomní obaja rodičia. V roku 2015 bolo takmer 75 % černošských rodín iba s jedným rodičom. Systém sociálnej podpory tento jav podporuje, pretože poskytuje oveľa viac príspevkov slobodným ako vydatým matkám. Tým, že sa rodič rozhodne zostať slobodný, získa viac vládnej podpory, vrátane sociálnej podpory, príspevkov na bývanie, potravinových lístkov a lekárskej starostlivosti. Systém sociálnej starostlivosti podporuje neúplné rodiny, čím podporuje ešte väčšiu chudobu. Oproti tomu Williams zistil, že miera chudoby medzi černošskými manželskými pármi zostala od roku 1994 na jednocifernej úrovni.<a href="#_edn25" id="_ednref25">[25]</a></p>
      <h4>Ľavicová stratégia: používanie politiky sociálnej starostlivosti na získavanie voličov</h4>
      <p>Hoci sa systém sociálnej starostlivosti v posledných niekoľkých desaťročiach rozrástol, medzera medzi bohatými a chudobnými sa tiež neustále zvyšovala. Priemerná mzda, zohľadňujúca infláciu, rastie slimačím tempom, pričom bohatstvo prúdi smerom k tým najbohatším, čo má za následok rozšírenie triedy chudobných pracujúcich. Ľavica tieto spoločenské problémy zdôrazňuje a snaží sa presadiť väčšiu vládu, vyššie zdanenie a viac sociálnej starostlivosti pre boj s chudobou, čím však problémy ešte zhoršuje.</p>
      <p>Ľavicovo zameraní politici používajú rozličné volebné slogany, ktorými presviedčajú voličov o svojich ušľachtilých zámeroch a sami seba potom vykresľujú ako tých, ktorí majú morálnu prevahu nad svojimi politickými protivníkmi. Pritom však vysávajú peniaze daňových poplatníkov, aby nimi pokrývali svoje programy. Ich metódou je len vziať bohatstvo vyššej a strednej triede a rozdeliť ho medzi chudobných. Tento systém nedobrovoľnej charity zakrýva vzťah medzi darcami (daňovníkmi) a príjemcami. Politici sa tak môžu prezentovať ako láskaví darcovia a získať vďačnosť príjemcov formou volebných hlasov. Súčasne s tým politici týmto príjemcom tvrdia, že by mali cítiť odpor k „bohatým“ – ktorí sú však skutočnými darcami.</p>
      <h3>b) Agresívna intervenčná politika v západných krajinách</h3>
      <p>V západných krajinách teraz štát, ktorý tradične iba prijímal a presadzoval zákony, začal výrazne ovplyvňovať tiež ekonomickú sféru. Podobne ako rozhodca na futbalovom zápase, štát začal byť zodpovedný za kontrolu a reguláciu kapitálu v ekonomike, ktorá sa kedysi z&nbsp;veľkej miery regulovala sama.</p>
      <p>V súčasnosti už vlády slobodného sveta uskutočňujú značné zásahy do svojich národných ekonomických systémov. Jednou z príčin tohto trendu bola Veľká hospodárka kríza v 30. rokoch. Po tejto kríze bola spoločnosť hlboko ovplyvnená teóriami keynesiánskej ekonómie, ktoré propagujú aktívne zásahy štátu a reguláciu hospodárstva použitím finančných injekcií. Vo svojom významnom diele <em>Všeobecná teória zamestnanosti, úroku a peňazí</em> (The General Theory of Employment, Interest and Money) britský ekonóm John Maynard Keynes nesúhlasí so samoreguláciou voľného trhu a namiesto toho uprednostňuje väčšie vládne výdavky a intervencie, ako je napr. bailout (finančná pomoc či vonkajšia záchrana pred bankrotom) na stabilizáciu trhu.</p>
      <p>V zdravej spoločnosti je úloha vlády obmedzená. Štát by mal zasahovať do ekonomiky len v mimoriadnych situáciách, ako napríklad počas prírodnej katastrofy či iných krízach. Ale dnes sa keynesiánska teórie uchytila ​​po celom svete. Vlády všetkých krajín sa čím ďalej tým viac snažia o väčšiu kontrolu nad svojimi ekonomikami.</p>
      <p>Keď vlády zohrávajú v ekonomike aktívnu úlohu, každý ich krok má masívny dominový efekt na trhy. Nové nariadenia a zákony môžu rozhodnúť o osude celých odvetví. Mnoho firiem a investorov je tak závislých na rozhodnutiach vlády.</p>
      <p>Aktívna finančná kontrola spolu s vysokou mierou sociálnej starostlivosti spôsobila, že mnohé vlády sa hlboko zadlžili. Podľa dát organizácie OECD vo viac ako tretine jej členských štátov dosahuje štátny dlh vyše 100% HDP. V jednej krajine dlh presiahol 237 % ich HDP.<a href="#_edn26" id="_ednref26">[26]</a> Spoločenská a ekonomická situácia mnohých krajín tak môže byť do budúcna veľmi nestabilná.</p>
      <p>Držiteľ Nobelovej ceny, ekonóm Ronald Coase, spísal niekoľko výskumných štúdií o vplyve vládnych zásahov do ekonomiky. Vo svojej práci Coase uvádza, že intervenčná politika takmer vždy prináša negatívne dôsledky. Najpravdepodobnejším vysvetlením je podľa neho to, že „vláda teraz operuje v tak masívnom meradle, že dosiahla fázu, ktorú ekonómovia nazývajú klesajúci výnos. Ak urobí čokoľvek ďalšie, situáciu to ešte zhorší“.<a href="#_edn27" id="_ednref27">[27]</a></p>
      <h4>Následky a realita intervenčnej politiky</h4>
      <p>Existujú najmenej dva zásadné dôsledky prílišného zasahovania štátu. Po prvé, moc štátu sa z hľadiska role a rozsahu zväčší. Vládni úradníci budú čoraz arogantnejší ohľadom svojich schopností. Budú zasahovať do ekonomiky a použijú štát, aby hral rolu spasiteľa. Potom, čo úspešne zamedzí kríze, vláda už pravdepodobne neopustí svoje rozšírené právomoci a funkcie.</p>
      <p>Po druhé, intervenčná politika vytvorí väčšiu závislosť na vláde. Keď sa ľudia dostanú do ťažkej situácie, alebo keď voľný trh nie je schopný poskytovať požadované výhody, po ktorých ľudia túžia, budú sa zasadzovať za viac štátnych zásahov, aby uspokojili svoje požiadavky.</p>
      <p>Ako sa moc štátu rozrastá, súkromné ​​podniky sa oslabujú a voľný trh má menej priestoru, v ktorom môže fungovať. Ľudia, ktorí z toho profitujú a začali byť závislí na politikoch, budú stále viac požadovať, aby vláda prevzala zodpovednosť za prerozdeľovanie majetku a prijala tomu zodpovedajúce zákony.</p>
      <p>Na Západe existuje silná politická tendencia tlačiaca spoločnosť smerom doľava. To zahŕňa ako nasledovníkov pôvodnej ľavice, vrátane socialistov a komunistov, tak aj tých, ktorých si obyčajne s ľavicou nespájame, ale ktorých si ľavica prisvojila. To posmeľuje ľavicových politikov, aby prijali väčšie opatrenia na zasahovanie do ekonomiky a zasahovanie do fungovania súkromných podnikov. Tento rozklad normálnej ekonomickej aktivity sa zdá byť zapríčinený rôznymi sociálnymi hnutiami, no v skutočnosti je za tým duch komunizmu.</p>
      <p>Západné vlády využívajú svoju úradnú moc, aby pod pláštikom rovnosti a iných politických výhovoriek posilňovali zásahy, pričom schvaľujú zákony, ktoré im dávajú trvalejšiu moc. Niet pochýb o tom, že takéto správanie zbavuje trhové ekonomiky ich hlavného sudcu – slobodnej vôle ľudí.</p>
      <p>Štát v podstate rozširuje svoju právomoc nad slobodným trhom, aby ho premenil na riadenú ekonomiku. Dlhodobo to smeruje k tomu, že všetky aspekty hospodárstva a ľudského živobytia sa dostanú pod kontrolu štátu. Ekonomické prostriedky sa použijú na to, aby upevnili politickú moc a zotročili spoločnosť a jej občanov.</p>
      <h3>c) Ako vedie socialistické hospodárstvo ku komunistickej totalite</h3>
      <p>Vysoké dane, veľká sociálna starostlivosť a rozšírené zásahy štátu sú prejavmi socializmu v západných ekonomických systémoch. Jediný rozdiel medzi rozsiahlou intervenčnou politikou štátov na Západe a plánovaným hospodárstvom v komunistických krajinách potom tkvie v tom, že v slobodných krajinách sú zákony, ktoré chránia ľudské práva a niektoré základné aspekty systému, aby sa nedostali pod úplnú kontrolu vlády.</p>
      <p>Rakúsky ekonóm a filozof Friedrich Hayek varoval pred štátom riadeným plánovaním a prerozdeľovaním bohatstva. Podľa neho sa tým nevyhnutne prekrúca trh a povedie to k vzostupu totality, a to bez ohľadu na to, či ide o demokratický systém alebo nie. Hayek veril, že aj keď je socializmus praktizovaný v Európe a Severnej Amerike je odlišný od štátneho vlastníctva a plánovaného hospodárstva, povedie napriek tomu k rovnakému výsledku. Ľudia prídu o svoju slobodu a živobytie, len s tým rozdielom, že pôjde o pomalší a nie taký priamy spôsob.<a href="#_edn28" id="_ednref28">[28]</a></p>
      <p>Ako bolo popísané predtým v tejto knihe, Marx, Engels aj Lenin vnímali socializmus ako nevyhnutný krok na ceste ku komunizmu. Pohyb vlaku smerom k&nbsp;cieľu neovplyvní, ak vlak zastaví po ceste na stanici. V skutočnosti tak môže nabrať ešte viac pasažierov. Duch komunizmu je tým, čo pôsobí ako hnacia sila posunu krajiny smerom k socializmu. Hneď ako ľudstvo opustí tradície, či už vo sfére ekonomiky alebo v iných odvetviach, a prijme ideológiu komunizmu, na tempe tohto vývoja nezáleží.</p>
      <p>Cieľ na konci tejto cesty však nie je nebo na zemi, ale skaza ľudstva. V skutočnosti sa duch komunizmu vôbec nestará o to, či sa nejaké „nebo“ uskutoční alebo nie, pretože ide o obyčajnú návnadu, ktorá má ľudskú spoločnosť priviesť do záhuby.</p>
      <h2>3. Dystopický socializmus ČKS</h2>
      <p>&nbsp;</p>
      <p>V roku 1978, potom, čo štátne vlastníctvo a plánované hospodárstvo priviedlo Čínu na pokraj chudoby, bola Čínska komunistická strana (ČKS) donútená zaviesť ekonomické reformy, aby sa udržala pri moci. ČKS sa teda vydala na cestu „reforiem a otvorenosti“ a prijala prvky voľného trhu. Mnohí tak získali dojem, že sa strana stala kapitalistickou. To je však ďaleko od pravdy.</p>
      <h3>a) Čínska ekonomika: naďalej v pevnom zovretí komunistickej kontroly</h3>
      <p>Pretože to poslúžilo svojmu účelu, ČKS uvoľnila niektoré aspekty čínskej ekonomiky, a dovolila napríklad súkromné ​​podnikanie. Ale to neznamená, že sa komunistické kádre vzdali kontroly. Aj napriek tomu, že existujú súkromné ​​podniky, strana ľuďom nikdy žiadne základné právo na súkromné ​​vlastníctvo nesľúbila. Všetky zdroje a pozemky sú v&nbsp;skutočnosti k&nbsp;dispozícii strane.</p>
      <p>Zároveň tiež ČKS používa štát na uvalenie prísnej kontroly vo veciach hospodárstva, vrátane rozsiahleho národného plánovania. Trh je len prostriedkom, ktorým štát stimuluje výrobu; nie je naozaj nezávislým a ani tu neexistujú inštitúcie, ktoré by slobodný trh podporovali.</p>
      <p>Čínsky komunistický model je monštruóznou kombináciou socializmu, etatizmu a trhovej ekonomiky. V Číne neexistuje právny štát a chýba aj transparentný systém vlastníckych práv. Nie je dovolené, aby sa výmenný kurz národnej meny vyvíjal svojím prirodzeným spôsobom. Tok bohatstva z krajiny a do nej je kontrolovaný a medzinárodné firmy pôsobiace v Číne sú pod tesnou kontrolou. ČKS využíva vládne dotácie a daňové úľavy na podporu a zvýšenie vývozu s cieľom poraziť konkurenciu v cenových vojnách. To narušilo normálny poriadok svetového obchodu. Práve z týchto dôvodov Svetová obchodná organizácia (WTO) dlhodobo odmieta uznať Čínu ako trhovú ekonomiku.</p>
      <p>Mnoho západných vlád naivne dúfalo, že ekonomický rozvoj prinesie do Číny slobodu a demokraciu. Namiesto toho ČKS použitím väčších finančných prostriedkov vystavila ľudí brutálnym a sofistikovaným formám represie. V júli 1999 začal režim prenasledovať sto miliónov ľudí, ktorí praktizujú kultivačnú disciplínu Falun Gong. Aby svoju kampaň mohla viesť na celonárodnej úrovni, ČKS značne navýšila a rozšírila právomoci bezpečnostným zložkám a utratila značné množstvo prostriedkov na vybudovanie vyspelých sledovacích systémov. Tých, ktorí boli v prenasledovaní „úspešní“, strana povýšila na vysoké posty. Táto vojna proti univerzálnym princípom pravdivosti, súcitu a znášanlivosti pokračuje dodnes. Nástroje, ktoré sa používali na prenasledovanie Falun Gongu, teraz strana používa na potláčanie ostatných vierovyznaní a širokej verejnosti. Od roku 2009 ČKS ročne minula vyše 500 miliárd jüanov (75 miliárd dolárov), aby pokryla náklady na „udržanie stability“, teda vlastne na udržanie dohľadu nad celou čínskou populáciou.</p>
      <h3>b) Pravda skrytá za ekonomickým vzostupom Číny</h3>
      <p>Kvôli rapídnemu nárastu hrubého domáceho produktu Číny počas posledných 40 rokov začali mnohí veriť v nadradenosť socialistickej ekonómie. Spôsobilo to, že veľa ľudí na Západe, vrátane elít v politických a akademických kruhoch, obdivuje efektívnosť totalitného systému.</p>
      <p>V skutočnosti však ekonomický model ČKS nemožno odkopírovať. Na jednej strane má socialistický systém napriek svojmu hospodárskemu rastu veľkú vnútornú nestabilitu. Na druhej strane stranícky model zakrýva všadeprítomnú korupciu vytvorenú bezohľadným politickým systémom. Hospodársky rast Číny bol z veľkej časti založený na nasledujúcich faktoroch.</p>
      <p>Prvým je, že uvoľnenie štátom vlastneného hospodárstva a centrálneho plánovania a tiež obnova súkromného sektora dala čínskemu hospodárstvu veľkú produktívnu silu. ČKS po celé desaťročia potláčala pracovný potenciál čínskych ľudí. Túžba vymaniť sa z chudoby v nich obnovila motiváciu podnikať a uvoľnila ohromný ekonomický potenciál. Veľká populácia Číny, zahŕňajúca viac ako miliardu ľudí, poskytla takmer nevyčerpateľnú zásobu lacnej pracovnej sily.</p>
      <p>Druhým faktorom bol masívny prísun západného kapitálu a technológií do Číny počas obdobia reforiem. V čínskom riadenom hospodárstve boli obrovské rozlohy nevyužitej pôdy, práce a trhov ako zlato, ktorého cena zatiaľ nebola určená. Spojenie kapitálových investícií a nerozvinutých zdrojov zapálilo iskru v ekonomickom raste Číny. Keby nebolo straníckej totalitnej vlády, mohol tento oheň prepuknúť o mnoho desaťročí skôr, a to oveľa kontrolovanejším a udržateľnejším spôsobom.</p>
      <p>Objem západných investícií v Číne je nesmierny. Podľa zverejnených čísel dosiahli priame americké investície v Číne v roku 2018 takmer 117 miliárd dolárov oproti 11 miliardám v roku 2000.<a href="#_edn29" id="_ednref29">[29]</a> Celková hodnota zahraničného kapitálu, ktorý vstúpil do Číny v rokoch 1979 až 2015, dosiahla podľa čínskeho ministerstva obchodu okolo 1,64 bilióna dolárov.<a href="#_edn30" id="_ednref30">[30]</a></p>
      <p>Západné krajiny dokonca poskytli ČĽR preferenčný obchodný režim a široký prístup na svoje trhy. V máji 2000 americká vláda priznala Číne štatút krajiny, s ktorými udržuje „permanentne normálne obchodné vzťahy“ („permanent normal trade relations“). 11. decembra 2001 Čína oficiálne vstúpila do Svetovej obchodnej organizácie (WTO) a pripojila sa k medzinárodnému trhu. Následne sa obrovské množstvo západného bohatstva presunulo do Číny a vďaka tomu sa z nej stala „svetová továreň“.</p>
      <p>Nemožno však zabudnúť, že ekonomická sila ČĽR sa rozvinula za použitia neetických praktík: Medzi tieto patrí extrémne vykorisťovanie pracovníkov, využívanie tzv. sweatshopov (podnikov s neľudskými pracovnými podmienkami) a nútenej práce vo väzenských táboroch po celej krajine, demolácia domov, nútené presídľovanie obyvateľov a&nbsp;podobné praktiky. Kvôli krátkodobému rastu ČKS ignorovala ničenie životného prostredia a iné riziká, len aby vyťažila každú kvapku zisku zo svojej pôdy, ľudí a zdrojov. ČKS využila západný kapitál, technológie, trhy, priaznivé komerčné postavenie a lacné domáce výrobné náklady, aby nahromadila obrovské sumy v devízových rezervách. Obchodný deficit medzi Spojenými štátmi a Čínou vzrástol zo zhruba 83 miliárd dolárov v roku 2000 na viac ako 345 miliárd v roku 2019.</p>
      <p>Napokon ČKS prevrátila zvyklosti medzinárodného obchodu a naplno využila dostupné príležitosti, bez ohľadu na ich legitimitu. Celonárodne si osvojila stratégiu privlastňovania duševného vlastníctva, aby predbehla iné krajiny v oblasti priemyslu a technológie. Ide o najväčší prípad krádeže v histórii. Správa z roku 2017 vydaná komisiou skúmajúcou krádež amerického duševného vlastníctva stanovila, že falšovaný čínsky tovar, pirátsky softvér a ukradnuté obchodné tajomstvá spôsobujú USA každým rokom stratu 225 až 600 miliárd dolárov. V sume nie sú zohľadnené straty spôsobené krádežami duševného vlastníctva. V správe sa uvádza, že počas posledných troch rokov prišli USA o 1,2 bilióna dolárov kvôli krádežiam duševného vlastníctva, z ktorých väčšinu spáchala Čína.<a href="#_edn31" id="_ednref31">[31]</a> Správa úradu riaditeľa národnej spravodajskej služby udáva, že 90 % kybernetických útokov na americké podniky má na svedomí čínsky režim, a spôsobujú každoročne odhadovanú škodu 400 miliárd dolárov.<a href="#_edn32" id="_ednref32">[32]</a></p>
      <p>Ekonomický model ČĽR využíva štátnu moc, aby privodil rapídny ekonomický rozvoj, pričom zároveň využíva podvodné triky pre zväčšenie konkurencieschopnosti. Ostatné štáty to tiež podnietilo k prijatiu väčších štátnych zásahov. Tieto krajiny urobili vážnu chybu, keď obdivujú stranícky model ako známku úspechu, a pritom ignorujú ľudské a morálne nešťastia.</p>
      <h3>c) Následky čínskeho ekonomického modelu</h3>
      <p>Ekonomický model ČKS spôsobil, že morálka ľudí v spoločnosti klesá dole voľným pádom, čo je presne v súlade s cieľom ducha komunizmu na zničenie ľudstva. Ekonomická moc strany ide ruka v ruke s rozkladom morálky a sťahuje ľudí do bezodného mora pôžitkárstva a smerom ku konečnému zničeniu.</p>
      <p>Dnes je Čína zaplavená falzifikátmi, otráveným jedlom, pornografiou, drogami, hazardnými hrami a gangami. Z korupcie a zhýralosti sa stali úspechy, ktorými sa ľudia môžu pýšiť, zatiaľ čo dôvera v spoločnosti už v podstate neexistuje. Rozširujúca sa priepasť medzi bohatými a chudobnými je sprevádzaná spoločenskými spormi a zneužívaním spravodlivosti. V mocenskej ekonomike ČĽR používajú funkcionári svoju autoritu na to, aby si nazhromaždili bohatstvo. Rozsah korupcie rastie úmerne s úrovňou postavenia a sprenevera v rádoch miliárd je bežným javom. Žiadna vláda nie je taká skorumpovaná a morálne zdegenerovaná ako čínsky komunistický režim.</p>
      <p>V tomto prostredí všadeprítomnej korupcie sa občania otáčajú chrbtom k utrpeniu svojich krajanov. V októbri 2011 bol svet ohromený smrťou Jüe-Jüe, dvojročného dievčatka z provincie Kuang-tung, ktorú zrazil kamión. Namiesto toho, aby sa jej vodič snažil pomôcť, prešiel Jüe-Jüe ešte raz, keď odchádzal preč. O niekoľko minút neskôr prešlo dievčatku nohy ďalšie vozidlo. 18 ľudí prešlo okolo tejto tragédie bez toho, aby Jüe-Jüe pomohli. Nakoniec jej pomohol až smetiar, ktorý nakoniec dostal plačúce dieťa do bezpečia. Jüe-Jüe neskôr v nemocnici zomrela. Medzinárodné médiá si kládli otázku, či Čína stratila svoju dušu. Môže byť pochopiteľné, keď sa ľudia zdráhajú prísť druhým na pomoc vo chvíli, keď im hrozí nebezpečenstvo, ako napríklad pri ozbrojenom prepadnutí. Jüe-Jüe však pre nikoho nepredstavovala hrozbu, keď ležala na ulici a umierala.</p>
      <p>Komunistické hnutie vedie k obrovskej deštrukcii tradičných hodnôt a&nbsp;kultúry. V komunistickej Číne už morálne štandardy klesli ďaleko za hranice toho, čo si človek dokáže predstaviť. Odber orgánov živým ľuďom, dobrým ľuďom, ktorí praktizujú duchovnú kultiváciu a usilujú sa o sebazdokonaľovanie, sa stal štátom schváleným zásahom. Neznámy počet väzňov svedomia bolo zabitých na operačných stoloch, pretože ich orgány boli odobraté kvôli zisku. Komunisti premenili zdravotnícky personál, ktorý má pomáhať ľuďom, na vrahov. Zlo ČKS zasiahlo celý svet; prostredníctvom ekonomických stimulov strana láka krajiny, ktoré majú presadzovať ľudské práva, aby si zakrývali oči pred jej zločinmi.</p>
      <p>Ekonomický rast bez morálky je chaotický, neudržateľný a má katastrofické následky. Kvôli neľudským praktikám ČKS narastajú spoločenské spory a životné prostredie je na pokraji kolapsu. Následky morálneho rozkladu sú fatálne. Čína nazýva samu seba silnou krajinou, ale jej sila je len ilúziou. Jej povrchný blahobyt, postavený na bezohľadnej honbe za bohatstvom, je odsúdený na zrútenie.</p>
      <p>Čína nebude mať dobrú budúcnosť, pokiaľ sa nedokáže vymaniť z osídiel ČKS. Duch komunizmu nemá v úmysle zabezpečiť zdravý a udržateľný rast, lebo jeho cieľom je zničiť Čínu a celý svet.</p>
      <p>&nbsp;</p>
      <h2>4. Devastujúce účinky socializmu v rozvojových krajinách</h2>
      <h3>a) Východná Európa v zovretí socializmu</h3>
      <p>Takmer tridsať rokov po páde Sovietskeho zväzu v krajinách východnej Európy naďalej pretrváva duch komunizmu, pretože tu nikdy nedošlo k úplnému zúčtovaniu so zločinmi, ktoré v týchto krajinách napáchal komunistický režim.</p>
      <p>Pretrvávajúcu prítomnosť komunizmu vo východnej Európe možno pozorovať v rôznych aspektoch politiky a ekonomiky. Napríklad Rusko a Bielorusko si ponechávajú silné štátom vlastnené podniky, vysokú mieru sociálnej starostlivosti a agresívnu intervenčnú politiku štátu. Počas prechodného obdobia od komunizmu, zažili krajiny východnej Európy krízy v podobe pomalého ekonomického rastu a veľkej miery nezamestnanosti. To všetko zvádzalo k tomu, aby sa tieto krajiny vrátili ku komunizmu a socializmu, ibaže v novej podobe. Ľavicové strany ožili s obnovenou silou a svoju energiu získali z pocitu nostalgie k socialistickej minulosti.<a href="#_edn33" id="_ednref33">[33]</a> Duch komunizmu nebol vypudený.</p>
      <h3>b) Socialistická ekonomika v rozvojových krajinách zlyhala</h3>
      <p>Medzi rozvojovými krajinami Ázie, Afriky a Latinskej Ameriky deklarovalo v 60. rokoch 20. storočia mnoho štátov, ktoré čerstvo nadobudli nezávislosť, svoju vernosť socializmu. Malo to pre nich katastrofálne následky. Na začiatku 21. storočia, ako priamy dôsledok jej socialistickej politiky, sa zrútila ekonomika Venezuely. Venezuela bývala kedysi najbohatšou krajinou Latinskej Ameriky. Teraz ju sužuje chudoba, zločinnosť a hlad. Zimbabwe bolo kedysi najbohatšou krajinou Afriky. Dnes ju postihla úplná katastrofa a miera inflácie predstihla aj tie najdivokejšie predstavy.</p>
      <h4>Venezuela: Ako socializmus zruinoval prosperujúcu krajinu</h4>
      <p>Venezuela má obrovské zásoby ropy. V 70. rokoch 20. storočia to bola najrýchlejšie rastúca ekonomika v Latinskej Amerike, ktorá sa tešila najnižšej úrovni príjmovej nerovnosti a najvyššiemu HDP na obyvateľa v regióne.<a href="#_edn34" id="_ednref34">[34]</a> Relatívne slobodná ekonomika Venezuely lákala schopných prisťahovalcov z Talianska, Portugalska a Španielska. Spolu s ochranou vlastníckych práv pomohli tieto faktory tamojšiemu hospodárstvu v rokoch 1940 až 1970 k rapídnemu rastu.<a href="#_edn35" id="_ednref35">[35]</a></p>
      <p>Potom, čo sa v roku 1999 chopil úradu nový prezident, začal osudný program znárodňovania, ktorý nakoniec priviedol ekonomiku Venezuely do chaosu. Venezuelský prezident verejne vyhlásil: „Musíme prekonať kapitalizmus. Nemôžeme sa však uchýliť k štátnemu kapitalizmu, ktorý by bol rovnakou zvrátenosťou ako v Sovietskom zväze. Musíme prijať socializmus ako tézu, ako projekt, ako cestu, ako nový typ humanistického socializmu, ktorý na prvé miesto stavia ľudí, a nie stroje alebo štát.“<a href="#_edn36" id="_ednref36">[36]</a></p>
      <p>Aby vybudovala socializmus, musela vláda Venezuely zabaviť či znárodniť mnoho súkromných spoločností naprieč odvetviami ropného priemyslu, poľnohospodárstva, financií, ťažkého priemyslu, oceliarstva, telekomunikácií, energetiky, dopravy a turizmu. Tento proces sa urýchlil po znovuzvolení prezidenta v roku 2007. Jeho vláda medzi rokmi 2007 a 2012 vyvlastnila 1 147 súkromných spoločností, a to s katastrofálnymi následkami.</p>
      <p>Firmy v predtým produktívnych odvetviach boli zatvorené a nahradené neefektívnymi štátnymi podnikmi, čo odstrašilo investorov. Ako výroba klesala, Venezuela začala byť veľmi závislá na importe tovarov. To, spolu so sériou vládnych zásahov do devízových rezerv a regulácie cien znamenalo nevyhnutnú katastrofu, hneď ako poklesla cena ropy. Niektorí videli príčiny tejto tragédie v ropnej kríze. No podľa dát poskytnutých Svetovou bankou sa sedem krajín, ktoré sú na exporte ropy závislé ešte viac ako Venezuela, tešilo ekonomickému rastu aj v rokoch 2013 až 2017.<a href="#_edn37" id="_ednref37">[37]</a></p>
      <p>Jadro problému vo Venezuele tkvie v socialistickom ekonomickom systéme. Hospodárska politika Venezuely v podstate nasledovala desať revolučných požiadaviek navrhnutých Marxom v Komunistickom manifeste, počnúc zrušením súkromného vlastníctva, cez vysoké zdanenie až po centralizovanú ekonomiku a výrobné prostriedky.<a href="#_edn38" id="_ednref38">[38]</a> Venezuelu dostihla ekonomická záhuba spôsobená komunistickým duchom.</p>
      <h4>Zimbabwe: ako sa obilnica Afriky premenila na krajinu hladujúcich ľudí</h4>
      <p>Po vyhlásení nezávislosti v roku 1980 sa Zimbabwe rozhodlo vybudovať socialistický štát podľa marxisticko-leninských princípov. Prvým predsedom vlády bol marxista, ktorý so svojimi partizánskymi oddielmi vedenými v duchu myšlienok Mao Ce-tunga, dostali bezvýhradnú pomoc zo strany ČĽR. Na rozdiel od iných afrických krajín, ktoré zaviedli socializmus, Zimbabwe nezaviedlo okamžite politiku znárodnenia.</p>
      <p>Ekonomické ťažkosti Zimbabwe sa začali v roku 2000, po začatí pozemkovej reformy. Pôda patriaca bielym farmárom bola zabavená a prerozdelená medzi černochov bez pôdy a tiež medzi tých, ktorí mali „správnu“ politickú príslušnosť. Mnohí nemali poľnohospodárske skúsenosti, čoho výsledkom bol prudký pokles poľnohospodárskej produktivity. V snahe vyhnúť sa kríze vytlačila rezervná banka Zimbabwe viac peňazí, čo viedlo k nekonečnej hyperinflácii. Údaje z centrálnej banky naznačujú, že v júni 2008 dosiahla ročná inflácia krajiny 231 miliónov percent. Do polovice novembra 2008 inflácia vrcholila na hranici takmer 80 miliárd percent, potom už úrady prestali zverejňovať mesačné štatistiky.<a href="#_edn39" id="_ednref39">[39]</a></p>
      <p>V roku 2008 Zimbabwe zasiahol veľký hladomor. Zo 16 miliónov obyvateľov trpelo päť miliónov ľudí hladom. Dodnes je v tejto krajine podvýživa chronickým a rozšíreným problémom.</p>
      <p>Komunizmus zamoruje svet spôsobmi, ktoré možno pozorovať alebo predpovedať vo všetkých krajinách. Vyspelé západné krajiny začínajú pociťovať krízy. Tragédia socializmu je už v rozvojových krajinách realitou. Ide vždy o tento princíp: komunistický duch používa ekonomiku, aby ľuďom sľúbil pohodlie a spokojnosť, potom zvedie ľudí do morálneho úpadku a zatiahne ich do priepasti chudoby.</p>
      <p>&nbsp;</p>
      <h2>5. Marxova teória vykorisťovania: prevrátenie dobra a&nbsp;zla</h2>
      <p>&nbsp;</p>
      <p>Marxizmus klame ľudí prostredníctvom súboru prepracovaných teórií, aby nahradil tradičné morálne štandardy svojimi umelými štandardmi, ktoré obracajú to, čo je dobré a zlé „hore nohami“. Z marxistického uhla pohľadu to, či je človek dobrý alebo zlý, nie je založené na jeho morálke a činoch, ale na jeho mieste v (inverznej) hierarchii kapitálu.</p>
      <p>Ten, kto patrí k tomu, čo marxisti nazývajú „kapitalistická“ trieda, je vinný z vykorisťovania proletariátu, a keďže proletariát je údajne utláčanou a vykorisťovanou triedou, jeho členovia prirodzene majú morálnu prevahu. Bez ohľadu na to, ako sa k majiteľom podnikov, vlastníkom nehnuteľností a bohatým ľuďom správajú, môžu mať „hlavu vztýčenú“. Marxizmus tak vlastníctvo majetku označil za zločin a obhajoval násilné vyvlastnenie.</p>
      <p>Marx povedal, že hodnotu vytvára iba práca. Ak vlastník spoločnosti tento rok investuje 10 miliónov dolárov a tržby spoločnosti v tomto roku sú 11 miliónov dolárov, podľa marxistickej teórie je zisk 1 milión dolárov „nadhodnotou“ vytvorenou zamestnancami, ktorú si ale kapitalista nespravodlivo prisvojil. Marx teda tvrdil, že vykorisťovanie bolo tajomstvom toho, ako kapitalisti zarábajú peniaze, a teda „pôvodným hriechom“ buržoázie. Marx potom dospel k záveru, že na odstránenie tohto hriechu musí byť zničená celá kapitalistická spoločnosť – to znamená, že buržoázia musí byť odstránená a jej majetok zabavený, zatiaľ čo predvoj strany skolektivizuje majetok a ustanoví komunizmus.</p>
      <p>Marxova teória vykorisťovania rozdeľuje ľudí do dvoch protikladných tried: buržoáziu s kapitálom a proletariát bez kapitálu. V skutočnosti však od chvíle, keď sa dostali do popredia industrializované spoločnosti, začala rýchlo rásť aj mobilita jednotlivých tried. Mobilita tried bola počas Marxovej éry (od začiatku 19. storočia až do polovice 19. storočia) podobná ako v 70. rokoch 20. storočia vo Veľkej Británii aj v Spojených štátoch.<a href="#_edn40" id="_ednref40">[40]</a> Prechod medzi triedami je dynamický proces; predpokladaný člen robotníckej triedy už nie je medzi robotníkmi, ak napríklad kúpi verejný kapitál v spoločnosti. Ak sa dá triedna príslušnosť tak ľahko zmeniť, pokusy o rozdelenie ľudí do podobných skupín nemajú iný účel ako podnecovať triednu nenávisť.</p>
      <p>V Číne, Sovietskom zväze a komunistických štátoch východnej Európy komunistické strany ukradli ľuďom pôdu, lynčovali statkárov a okradli majiteľov podnikov o ich podniky. Vraždili „triednych nepriateľov“, zabavovali bohatstvo nazhromaždené generáciami rodín a viedli kampane štátneho terorizmu proti ľuďom. Všetko toto zlo bolo výsledkom nenávistných teórií komunizmu. Medzitým boli tradičné morálne normy, rovnako ako viera v Boha, svätci a významní učenci onálepkovaní ako patriaci k „triede vykorisťovateľov“ a mali byť napádaní a vyhladení.</p>
      <p>Marxové teórie boli v ekonomických a filozofických kruhoch široko kritizované. Tu uvádzame iba niekoľko príkladov, ktoré ilustrujú absurdnosť Marxovej teórie vykorisťovania.</p>
      <p>Marx tvrdí, že práca vytvára hodnotu a táto hodnota je daná pracovnou dobou potrebnou pre výrobu. To je smiešna teória. Hodnota tovaru nie je jedinou z jeho vlastností. Väčšinou ľudia pridávajú ku každej komodite subjektívne prvky – predovšetkým ponuku a dopyt.</p>
      <p>Mnohí ekonómovia preskúmali proces oceňovania a na rozdiel od Marxovho úzkeho pohľadu väčšina ekonomických mysliteľov verí, že do tvorby hodnoty výrobku je zapojených veľa faktorov – vrátane pôdy, kapitálu, práce, vedy a technológie, manažmentu, rizika investícií atď. Ekonomické aktivity sú zložitým systémom, zahŕňajúcim rôzne články výrobného reťazca. Rôzne výrobné faktory majú určité požiadavky na riadenie procesov a rôzni ľudia pri ňom tiež hrajú rôzne role, ktoré sú nevyhnutné pre celý reťazec výroby a prispievajú k vytváraniu hodnoty.</p>
      <p>Napríklad majiteľ podniku plánuje minúť jeden milión dolárov za to, že najme dvoch inžinierov, aby navrhli a vyrobili novú hračku. Najme tiež odborníka na marketing, aby podporil predaj novej hračky. O dva roky neskôr získa nová hračka popularitu a prinesie zisk 50 miliónov dolárov. Je to práca inžinierov a marketingového odborníka, ktorí vytvorili hodnotu 50 miliónov dolárov? Samozrejme, že nie. Dôvodom, prečo nová hračka zarobila milióny, je to, že ju ľudia chceli. To, ako podnikateľ rozumie trhu, jeho schopnosť organizovať a riadiť ostatných a odvaha prijať riziko, to všetko prispelo k hodnote hračky.</p>
      <p>Predpokladajme, že nápad tej hračky pochádza od jedného z inžinierov – potom teda hodnota 50 miliónov dolárov pochádza zo skutočnosti, že majiteľ podniku vykorisťoval inžinierovu kreativitu, bez toho, aby mu na oplátku niečo dal? Samozrejme, že nie. Ak by si inžinier myslel, že jeho kreativita nebola adekvátne odmenená, mohol si nájsť prácu u inej spoločnosti, ktorá ponúka vyšší plat.</p>
      <p>Na voľnom trhu sa nakoniec dosiahne rovnováha medzi zručnosťami, ambíciami a kapitálom. Podnikatelia, ktorí požadujú neprimerané zisky, prehrajú s konkurenciou alebo nebudú schopní prilákať talentovaných ľudí. Vzhľadom k tomu, že musí čakať na návrat investovaného kapitálu a nemôže tento kapitál hneď míňať, či ho inak požívať, sú zisky dosahované aj vďaka úsiliu investora. Preto je normálne, že dodatočná suma bude získaná odmenou za vynaložené investície. Princíp sa nelíši od pôžičky na úrok.</p>
      <p>Pri rozhodovaní o hodnote tovaru hrá úlohu aj veľa „náhodných“ faktorov. Tieto náhodné faktory možno rozumne vysvetliť len z uhla pohľadu, ktorý je založený na tradičných vierach a kultúre.</p>
      <p>V určitých situáciách nemusí vytváranie a zničenie hodnoty nijako súvisieť s otázkou práce. Diamant, ktorý má dnes hodnotu 10 miliónov dolárov, mohol byť pred piatimi tisíckami rokov bezcenný, pretože ho nikto nechcel. Neúrodný kúsok zeme zdedený po dedkovi môže byť stokrát cennejší vďaka prosperite neďalekého mesta alebo vďaka objavu vzácnych kovov pod zemou. Tu zvýšenie hodnoty nezahŕňa žiadnu prácu. Takémuto obrovskému, nečakanému bohatstvu sa jednoducho hovorí šťastie. Západné i východné kultúrne tradície uznávajú, že bohatstvo je požehnaním, ktoré človeku dávajú nebesia.</p>
      <p>Aby Marx demonštroval „racionalitu“ a „nutnosť“ štátneho vlastníctva, vymyslel teóriu vykorisťovania založenú na nadhodnote, ktorá zmenila ekonomické aktivity, ktoré ľudia vykonávajú ako normálnu súčasť života, na negatívne a neetické správanie. Jeho teória podnietila nenávisť a opovrhnutie voči existujúcemu ekonomickému poriadku, čo bolo súčasťou jeho snahy podkopať ho a zvrhnúť.</p>
      <p>Zamestnávatelia a robotníci, statkári a roľníci v skutočnosti tvoria skupinu spoločných záujmov. Ich vzťah by mal byť vzťahom spolupráce a vzájomnej závislosti; každá skupina podporuje druhú, aby prežila. Marx zámerne zveličoval rozdiely medzi triedami a považoval ich za absolútne – akoby medzi nimi panoval nepriateľský vzťah na život a smrť.</p>
      <p>V skutočnosti sú medzi zamestnávateľmi dobrí aj zlí ľudia, a rovnako tak je tomu medzi robotníkmi. Kto by mal byť v ekonomickej výmene skutočne odhaľovaný a sankcionovaný, nie je nik iný ako tí, ktorí porušujú etické normy správania. Základom pre posudzovanie človeka by mali byť jeho morálne kvality a správanie, nie jeho majetok.</p>
      <p>Ľudia môžu svoje ekonomické a sociálne postavenie vlastným úsilím zmeniť. Robotníci sa môžu stať investormi vďaka tomu, že si nahromadia zarobené peniaze. Z investorov sa môžu stať robotníci, ak zlyhajú ich investície. Spoločnosť sa neustále mení a prúdi ako rieka. Úlohy tých, čo pracujú a tých, čo investujú sa v modernej spoločnosti často menia. Väčšina ľudí hrá obe role – vkladajú zisky, ktoré zarobili, do budúcej produktívnej kapacity, čím vytvárajú pracovné miesta, zvyšujú bohatstvo spoločnosti a má z toho prospech široká verejnosť. Dokonca aj zakladateľ amerického odborového hnutia prehlásil: „Najhorším zločinom proti pracujúcim je firma, ktorá nie je zisková.“<a href="#_edn41" id="_ednref41">[41]</a></p>
      <p>Absurdná „teória nadhodnoty“ označuje bežné činnosti vlastníkov pôdy a podnikateľov nálepkou „vykorisťovania“. Podnietila v ľuďoch nesmiernu nenávisť a boj, zmiatla im myslenie a miliónom ľudí zničila život.</p>
      <p>&nbsp;</p>
      <h2>6. Nenávisť a závisť: pôvod absolútneho rovnostárstva</h2>
      <p>&nbsp;</p>
      <p>Komunizmus sa zasadzuje za absolútne rovnostárstvo. Na povrchu to môže znieť ako vysoký ideál a viesť ľudí k tomu, aby slepo verili v jeho správnosť. V skutočnosti to však vyvoláva nenávisť a závisť, pretože ľudia, ktorí veria v absolútne rovnostárstvo, nedokážu tolerovať úspech ostatných alebo ľudí, ktorí sú bohatší a majú lepší život, ľahšiu prácu a luxusnejšie životné podmienky. Každý musí byť rovnaký, a v tomto duchu vyznávači rovnostárstva hovoria: „Mal by som mať to, čo máš ty, a čo dostaneš ty, ja by som mal dostať tiež.“ Podľa takéhoto pohľadu sú si všetci rovní a celý svet je rovnaký.</p>
      <p>Absolútne rovnostárstvo sa prejavuje prinajmenšom dvoma hlavnými spôsobmi. Po prvé, keď si ľudia ešte nie sú rovní, začnú byť povzbudzovaní k tomu, aby neboli spokojní so svojím ekonomickým postavením. Ľudia začnú prahnúť po tom, čo majú ostatní, a dokonca to začnú vyhľadávať nečestnými alebo násilnými prostriedkami. V extrémnych prípadoch ničia majetok ostatných a dokonca zabíjajú, aby zbohatli.</p>
      <p>Najhorším prejavom týchto tendencií je násilná revolúcia. S cieľom vyvolať nespokojnosť Marx rozdelil spoločnosť do dvoch protikladných tried: tých, ktorí vlastnia výrobné prostriedky, a tých, ktorí ich nevlastnia. Na vidieku to boli statkári a roľníci, v mestách majitelia podnikov a robotníci. Cieľom bolo podnietiť triednu nenávisť a použiť ľudí, ktorým boli údajne odňaté občianske výsady, k uskutočneniu násilnej revolúcie. Roľníci sú chudobní a statkári bohatí – zmocnite sa ich bohatstva! Všetci by mali byť bohatí! ČKS preto vyzvala roľníkov, aby sa zapojili do „pozemkovej reformy“ – čo znamenalo zaútočiť na statkárov a rozdeliť si ich pôdu. Ak statkári odmietli pôdu vydať, boli zabití. Komunistická strana to urobila tak, že najskôr podnietila chuligánov, aby vytvárali problémy, potom povzbudila roľníctvo, aby sa k nim pridalo v povstaní a útokoch na triedu statkárov. Milióny statkárov (vlastníkov pôdy) prišlo o život.</p>
      <p>Hneď ako skupiny viac-menej dosiahnu stav „rovnosti“ – keď každý dostane rovnaký podiel výhod – každý, kto vyčnieva z radu, je potrestaný. S každým sa zaobchádza rovnako, bez ohľadu na to, či pracuje viac, menej alebo vôbec. Popiera to však univerzálny princíp: hoci sa ľudia na povrchu javia rovnakí, osobnosť každého jednotlivca, jeho intelekt, fyzická sila, morálka, povolanie, rola v spoločnosti, vzdelanie, životné podmienky, schopnosť znášať ťažkosti, vytrvalosť, vynaliezavosť a tak ďalej, to všetko sa líši. A to, ako človek prispieva spoločnosti, sa tiež líši. Prečo by sa teda mal uplatňovať rovnaký výsledok na všetkých? V tomto zmysle je nerovnosť vlastne skutočnou rovnosťou, zatiaľ čo rovnosť, ktorú sleduje komunizmus, je skutočnou nerovnosťou a skutočnou nespravodlivosťou.</p>
      <p>Starí Číňania hovoria, že nebesia odmenia človeka podľa úsilia, ktoré vynaložil. Absolútne rovnostárstvo je v reálnom svete neuskutočniteľné.</p>
      <p>Pod rúškom rovnostárstva leniví ľudia čerpajú výhody, zatiaľ čo tí, ktorí sú schopní a tvrdo pracujú, sú penalizovaní alebo dokonca neznášaní a nenávidení. Každý spomaľuje svoje tempo, aby zodpovedalo rýchlosti tých najpomalších. V skutočnosti to spôsobí, že každý začne byť lenivý, čaká, až niekto iný niečo urobí, aby to mohol využiť a získať niečo za nič, alebo niečo druhému ukradnúť, čo vedie k rozsiahlemu morálnemu úpadku.</p>
      <p>Nenávisť a závisť, ktoré poháňajú absolútne rovnostárstvo, sú jedovatými koreňmi ekonomického hľadiska komunizmu. Ľudská povaha má v sebe obsiahnuté dobro aj zlo. Západné viery hovoria o siedmich smrteľných hriechoch, zatiaľ čo východná kultúra učí, že človek má ako budhovskú, tak aj démonickú povahu. Budhovská povaha sa prejavuje ako láskavosť, schopnosť znášať ťažkosti a myslenie na druhých. Démonická povaha sa prejavuje ako sebectvo, lenivosť, žiarlivosť, zloba, nenávisť, hnev, žiadostivosť a tyrania, a tiež ako ľahostajnosť k životu, podnecovanie sváru, šírenie fám, získavanie niečoho za nič a tak ďalej.</p>
      <p>Ekonomický model, ktorý si osvojil komunizmus, zámerne stimuluje démonickú povahu človeka, zosilňujúc závisť, chamtivosť, lenivosť a iné zlé prvky, čo spôsobí, že ľudia stratia svoju ľudskosť a opustia tradičné hodnoty uznávané po tisíce rokov. Zosilňuje najhoršie prvky ľudskej povahy a mení ľudí na komunistických revolucionárov.</p>
      <p>Ekonóm a filozof Adam Smith, ktorý žil v 18. storočí, v diele <em>Teória mravných citov</em> napísal, že morálka je základom prosperity ľudstva. Dodržiavanie všeobecných morálnych pravidiel je „nevyhnutné pre samotnú existenciu ľudskej spoločnosti, ktorá by sa rozpadla do ničoty, pokiaľ by väčšina ľudstva nepociťovala úctu k týmto dôležitým pravidlám správania“.<a href="#_edn42" id="_ednref42">[42]</a></p>
      <p>Lawrence Kudlow, riaditeľ Národnej ekonomickej rady USA, sa domnieva, že ekonomická prosperita musí existovať spoločne s morálkou. V roku 1997 napísal, že ak Spojené štáty dokážu dodržiavať „najdôležitejší princíp“ – teda dodržiavať morálne hodnoty, na ktorých bola Amerika založená – rozvoj Spojených štátov nebude mať hraníc.<a href="#_edn43" id="_ednref43">[43]</a></p>
      <h3>a) Ekonomické rovnostárstvo: odrazový mostík ku komunizmu</h3>
      <p>Pod vplyvom absolútneho rovnostárstva znejú na Západe intenzívne požiadavky na „sociálnu spravodlivosť“ a tiež na prijatie zákonov o minimálnej mzde, pozitívnu diskrimináciu a ďalšie požiadavky. Za týmito požiadavkami stojí túžba po rovnosti výsledkov, ktorú môžu komunistické elementy využiť. Nezáleží na tom, či nejaké zraniteľné skupiny získajú rovnosť alebo si zlepšia svoje sociálne postavenie. Z pohľadu komunistov sú to len pešiaci, ktorí podnecujú nenávisť.</p>
      <p>Ak komunisti dostanú to, čo požadujú, budú jednoducho vznášať nové požiadavky na rovnosť a nebude to mať konca. Ak svoje požiadavky nedosiahnu, posilnia predstavy ľudí o spravodlivosti konceptu rovnosti a urobia z neho hlavnú platformu, aby tak získali väčší vplyv na verejnú mienku. Pretože komunizmus podnecuje nevôľu vo viacerých oblastiach a toľkými rôznymi prostriedkami, ak sa bude môcť šíriť nekontrolovane, nevyhnutným výsledkom bude sociálny nepokoj. Komunisti budú vždy schopní nájsť zraniteľné skupiny a potom pre nich požadovať finančnú alebo sociálnu rovnosť, pričom tento postup budú opakovať, kým nebude vydláždená cesta ku komunizmu.</p>
      <p>Implementácia týchto požiadaviek navyše často vedie k opaku toho, čo je sľúbené. Tí, ktorí majú byť týmito politikami chránení, namiesto toho strácajú. Zoberme si ako príklad zákon o minimálnej mzde. Na povrchu je jeho cieľom chrániť práva pracovníkov, ale výsledkom je, že mnoho podnikateľov jednoducho prestane prijímať zamestnancov, pretože je to pre nich neekonomické. Výsledkom je, že pracovníci prichádzajú o prácu. Eliminácia pracovných miest s nízkou mzdou tiež znamená menej príležitostí pre mladých ľudí, keďže takto strácajú príležitosti na zaučenie sa a prepracovanie sa k lepšie plateným miestam. Prístup „jedna veľkosť pre všetkých“ tiež porušuje ekonomickú teóriu a vedie k nadmerným vládnym zásahom.</p>
      <p>Ľudia tiež používajú výhovorku „rovnakej mzdy za rovnakú prácu“ a požadujú sociálnu revolúciu na základe boja s rasizmom a sexizmom. Citujú štatistiky ako napríklad, že priemerná mzda čiernych mužov je nižšia ako priemerná mzda bielych mužov, že priemerná mzda žien je nižšia ako priemerná mzda mužov a že tieto rozdiely sú výsledkom rasizmu a sexizmu. V skutočnosti takéto porovnania nie sú na mieste. Ak porovnáme jablká s jablkami, výsledky budú odlišné. Vedci zistili, že pri černošských rodinách, v ktorých manžel aj manželka vyštudovali vysokú školu, sú ich príjmy v skutočnosti o niečo vyššie ako u obdobne situovaných bielych rodín.<a href="#_edn44" id="_ednref44">[44]</a> Potom, čo komunisti desiatky rokov usilovali o zničenie tradičnej rodiny a propagovali výhody sociálneho zabezpečenia, je černošských rodín tohto typu relatívne menej, a to je hlavný dôvod, prečo medzi rasami panujú celkové rozdiely v príjmoch. Vytváranie zmysluplných a presných porovnaní by malo byť samozrejmé, ale komunistické prvky majú tendenciu podnecovať nepokoje a boje, čo vedie ľudí k tomu, že sa na veci pozerajú iracionálne.</p>
      <p>Komunizmus sa nestará o blahobyt zraniteľných skupín. Zaujíma sa len o slogany, ktoré vedú ľudí na cestu do záhuby.</p>
      <h3>b) Komunizmus využíva odbory k podkopávaniu slobodných spoločností</h3>
      <p>Strata pracovných miest vo výrobnom sektore USA za posledných niekoľko desaťročí je dobre známym javom. Mnoho ľudí si však neuvedomuje, že jedným z hlavných vinníkov sú odbory, ktoré ľavica využila pre svoje účely. Mnohí odborári dnes tvrdia, že pomáhajú získať výhody pre robotnícku triedu, ale v skutočnosti robia opak. Je to zrejmé, keď sa pozrieme na históriu odborov a zmenu ich poslania v priebehu rokov.</p>
      <p>Odborové zväzy založili členovia robotníckej triedy, ktorí mali malú alebo vôbec žiadnu odbornosť, za účelom vyjednávania s vedením. Odborový zväz je do určitej miery schopný sprostredkovať a riešiť konflikty medzi pracovníkmi a majiteľmi. Komunistické elementy však odbory zmenili na nástroj podporujúci komunistické hnutia a ich politiku. Odbory sa stali mocnou zbraňou na zničenie slobodného podnikania a na vedenie politického boja.</p>
      <p>Friedrich Engels na túto tému napísal: „nezadržateľne sa tiež blíži doba, kedy robotnícka trieda pochopí, že boj za vysoké mzdy a krátku pracovnú dobu a celá činnosť odborových zväzov, ako je teraz uskutočňovaná, nie je sama osebe cieľom, ale prostriedkom, veľmi nevyhnutným a účinným prostriedkom, zároveň však len jedným z niekoľkých prostriedkov smerujúcich k vyššiemu cieľu: úplnému zrušeniu mzdového systému.“<a href="#_edn45" id="_ednref45">[45]</a></p>
      <p>Lenin veril, že vytvorenie a legalizácia odborov je pre robotnícku triedu dôležitým prostriedkom na získanie kontroly nad „kapitalistickou“ triedou a že odbory sa stanú pilierom komunistickej strany a kľúčovou silou v triednom boji.</p>
      <p>Vo svojom prejave Lenin navrhol, aby sa odbory stali „školou administratívy, školou hospodárskeho riadenia, školou komunizmu“ a spojovacím prvkom medzi komunistickou stranou a masami. Každodennou prácou odborov bolo presvedčiť masy, aby prešli z kapitalizmu na komunizmus. „Odbory sú ,rezervoáromʻ štátnej moci,“ vyhlasoval Lenin.<a href="#_edn46" id="_ednref46">[46]</a></p>
      <p>Od polovice až do konca devätnásteho storočia komunistické a ľavicové sily využívali odbory na podnecovanie zamestnancov k veľkým štrajkom, kladeniu prísnych požiadaviek na majiteľov a dokonca aj k násilným opatreniam, napríklad ničeniu strojov a&nbsp;tovární. V októbri 1905 sa viac ako 1,7 milióna robotníkov v Rusku zúčastnilo celonárodného politického štrajku, ktorý ochromil hospodárstvo krajiny. V tom čase bola vytvorená pomerne agresívna odborová organizácia Centrálna skupina pracujúcich, ktorá sa stala hlavným predchodcom Petrohradského sovietu. Táto „rada“ robotníkov a vojakov sa neskôr stala hnacím motorom ruskej revolúcie.<a href="#_edn47" id="_ednref47">[47]</a></p>
      <p>Odbory v západných a rozvinutých krajinách sú tiež široko infiltrované a využívané komunistickými faktormi. Zamestnávatelia a ich zamestnanci by mali byť v symbiotickom vzťahu. Napriek tomu sa komunisti snažia vyvolať, rozšíriť a zintenzívniť konflikt medzi nimi. Odbory sa používajú na eskaláciu konfliktov pri procese vyjednávania medzi vedením a pracovníkmi. Odbory navyše zdôvodňujú a zintenzívňujú konfrontačnú stránku vzťahu medzi vedením a pracovníkmi a používajú ju na legimitizáciu svojej vlastnej existencie. Rozdúchavajú nespokojnosť u pracovníkov a zo všetkých problémov obviňujú „kapitalistov“. Toto je jeden z kľúčových bodov pre prežitie odborov.</p>
      <p>Odborové zväzy na prvý pohľad bojujú za záujmy pracovníkov, v skutočnosti však podkopávajú priemyselnú konkurencieschopnosť. Existujú na to dva dôvody. Po prvé, pod zámienkou ochrany práv a záujmov pracujúcich, odbory sťažujú podnikom možnosť prepúšťať zamestnancov, ktorí nepracujú dobre a dosahujú slabé výsledky. Vzniká tým kultúra lenivosti. Nielen, že je to nespravodlivé voči zamestnancom, ktorí pracujú usilovne, ale tiež to spôsobí, že sú potom menej aktívni. Najdôležitejším faktorom rastu spoločnosti sú jej pracovníci, ale ako odbory ochraňujú zamestnancov, ktorí neplnia svoje úlohy, podniky strácajú svoju konkurencieschopnosť. Podniky, ktoré nespĺňajú tieto požiadavky odborov, sú potom terčmi bojov vrátane štrajkov a protestov, ktoré podniky ďalej oslabujú. Silná odborová organizácia United Auto Workers reprezentujúca pracovníkov automobilového priemyslu v&nbsp;Detroite, bežne vyzývala ku štrajkom. Pred finančnou krízou v roku 2008 odbory požadovali vyplácať 70 dolárov na hodinu ako mzdu a benefity. V dôsledku toho sa americký automobilový priemysel ocitol na pokraji bankrotu.<a href="#_edn48" id="_ednref48">[48]</a></p>
      <p>Po druhé, pod zámienkou ochrany sociálneho zabezpečenia zamestnancov (vrátane dôchodkov, zdravotného poistenia a podobne) odbory neustále zvyšujú náklady podnikov. Firmy tak obmedzujú svoj rast a sú nútené znižovať&nbsp; investície do výskumu a vývoja, čo poškodzuje ich konkurencieschopnosť. Výsledkom je tiež to, že spoločnosti musia zvyšovať ceny výrobkov, čo poškodzuje záujmy spotrebiteľov. Štúdie ukazujú, že to je dôvodom, prečo spoločnosti bez odborov, ako Toyota a Honda, boli schopné vyrábať vysoko kvalitné autá za nižšie ceny a prečo sa americké automobilové továrne v Detroite s odbormi stali menej konkurencieschopnými.<a href="#_edn49" id="_ednref49">[49]</a></p>
      <p>Platia tak slová Edwina Feulnera, zakladateľa think tanku American Heritage Foundation, ktorý na tému odborov vyhlásil: „Fungujú ako príťaž na krku spoločnosti – je kvôli nim menej flexibilná a nie je schopná reagovať múdro na požiadavky meniaceho sa trhu.“<a href="#_edn50" id="_ednref50">[50]</a></p>
      <p>Strata pracovných príležitostí vo výrobnom priemysle USA je všeobecne známym javom, tak ako aj debaty o tomto niekoľko desaťročí trvajúcom jave. Mnohí ľudia si však neuvedomujú, že kľúčovým faktorom straty pracovných miest sú práve odbory. Počet pracovných miest vo výrobe sa vo firmách s odbormi v rokoch 1977 až 2008 znížil o&nbsp;75 %, zatiaľ čo vo firmách bez odborov sa v rovnakom období zvýšil o 6%. Vyplýva to zo správy The Heritage Foundation.</p>
      <p>Situácia v stavebníctve je podobná. Výskumník Heritage Foundation, James Sherk, uvádza: „Na rozdiel od výrobného sektoru stavebný priemysel od konca 70. rokov 20. storočia značne vzrástol. Avšak z celkového pohľadu nastal rast výhradne v zamestnaniach, kde nie sú odbory. Od roku 1977 došlo k&nbsp;rozšíreniu počtu týchto pracovných miest o&nbsp;159 %. Na pozíciách v stavebníctve, kde majú odbory, sa počet pracovných namiesto znížil o&nbsp;17 %. “<a href="#_edn51" id="_ednref51">[51]</a></p>
      <p>Odbory sú navyše nástrojmi, ktoré komunistické prvky používajú v podnikoch na podporu rovnostárstva. Sherk poznamenáva, že odbory požadujú, aby spoločnosti vyplácali mzdy podľa počtu odpracovaných rokov zamestnanca (to isté sa deje v socialistických krajinách) bez ohľadu na prínos zamestnanca pre spoločnosť alebo jeho výkon. „Odborové dohody stláčajú mzdy: znižujú mzdy produktívnejších pracovníkov a zvyšujú mzdy tých menej kompetentných.“<a href="#_edn52" id="_ednref52">[52]</a></p>
      <p>Cieľ za tým je rovnaký, ako u absolútneho rovnostárstva za komunizmu. Teda v skutočnosti prerozdeliť bohatstvo medzi zamestnancami v rámci podniku. Zásahy do vnútorného rozhodovania podnikov a monopol na trhu práce narúšajú voľný trh.</p>
      <p>Odborové zväzy agresívne obhajujú to, čo označujú ako sociálne zabezpečenie pracovníkov, no v konečnom dôsledku to zvýhodňuje niektorých pracovníkov pred ostatnými a brzdí jednotlivé spoločnosti a hospodárstvo ako celok. Prieskum vykonaný v roku 2005 ukázal, že „väčšina odborových domácností nesúhlasí s americkými odbormi“ a že „hlavný dôvod ich nesúhlasu nie je nikdy otvorene diskutovaný v odborových médiách ani na odborových schôdzach“.<a href="#_edn53" id="_ednref53">[53]</a></p>
      <p>Odbory infiltrované komunizmom a vedené progresívnym hnutím sa často stali nástrojmi boja proti voľnému trhu. Korupcia a obrovské osobné záujmy sú medzi odborovými predákmi bežné. Ich úzko zameraný boj proti tomu, čo označujú za „nespravodlivosť“ na pracovisku vytvára záťaž pre priemysel a produktivitu, bráni podnikovým reformám a racionálnym pokusom o racionalizáciu výroby, služieb, vzdelávania, vládnej byrokracie a ďalších oblastí. Ľavica čerpá pomoc od odborov a využíva ich k presadzovaniu svojich sociálnych hnutí a&nbsp;na rozdeľovanie spoločnosti.</p>
      <h2>7. Komunistické „ideály“: vábenie človeka k vlastnému sebazničeniu</h2>
      <p>Napriek tomu, že komunistická teória je plná medzier a rozporov, mnohí sú ňou stále oklamaní. Je to tak preto, že Marx opísal utopický komunistický raj, z ktorého by sa ľudia na celom svete mohli tešiť. To je ústredná predstava a klam komunizmu. Marx vyobrazuje spoločnosť, ktorá bude oplývať „materiálnou hojnosťou“ a bude mať oveľa vyššie morálne štandardy. Každý človek bude pracovať „podľa svojich schopností“ a bude dostávať „podľa svojich potrieb“. Neexistovalo by žiadne súkromné ​​vlastníctvo, žiadne rozdiely medzi bohatými a chudobnými, žiadna vládnuca trieda a žiadne vykorisťovanie. Pre všetkých by existovala sloboda a rovnosť a každý človek by bol schopný rozvíjať svoje konkrétne nadanie. Život by bol úžasný.</p>
      <p>Tento súbor klamlivých argumentov prilákal mnohých, aby za ne bojovali. Mnohí dnešní ľudia zo Západu nemajú žiadnu tragickú skúsenosť zo života v totalitnom štáte. Stále v sebe prechovávajú iluzórnu predstavu komunistického raja, a preto obhajovaním komunistických a socialistických ideálov ešte prilievajú olej do ohňa.</p>
      <p>V skutočnosti sú všetky myšlienky predložené Marxom nebezpečné ilúzie. Marxizmus tvrdí, že komunistická spoločnosť sa bude tešiť z nadbytku hmotných statkov. Avšak ľudské túžby a potreby sú nekonečné. Keďže ľudské poznanie je obmedzené, pracovná doba je obmedzená a zdroje sú obmedzené tiež, určite niekedy nastane nedostatok. Toto je najzákladnejší východiskový bod všetkých ekonomických štúdií. Ak by nebolo týchto obmedzení, ľudia by nemuseli skúmať, ktorý spôsob výroby je najefektívnejší, pretože ten predpokladaný nadbytok by poskytoval všetko a mohol by sa ľubovoľne premrhať.</p>
      <p>Marxizmus tiež tvrdí, že morálne štandardy by sa v komunistickej spoločnosti výrazne zlepšili. Dobro a zlo sú však prítomné v každom človeku a zlepšenie morálnych štandardov vyžaduje nasledovanie spravodlivej viery a hodnôt, ako aj osobné úsilie o zušľachťovanie seba samého. To, čo marxizmus hlása, je ateizmus a triedny boj, ktoré zosilňujú zlú stránku človeka. Ľudia nemôžu mať slobodu viery a náboženstvo je len politickým nástrojom komunistickej strany. Ba čo viac, počas vlády komunizmu sú náboženské inštitúcie používané k ochraňovaniu tyranie, klamaniu sveta, vzdorovaniu a oponovaniu Nebesiam a k odvracaniu ľudí od toho, čo je božské. Bez spravodlivej viery v Nebesia a&nbsp;bez sebadisciplíny môže morálka ľudí iba upadať. Navyše, ihneď ako sa dostanú k moci, všetci komunistickí vodcovia sa ukážu ako tyrani – arogantní, oplzlí a úplne neetickí. Očakávať, že ich nasledovníci budú stelesňovať a výrazne zlepšovať morálne štandardy, je v rozpore so zdravým rozumom.</p>
      <p>Marxizmus tiež hlása, že bude existovať rovnosť pre všetkých. Ale ako už bolo povedané, socializmus nevyhnutne vedie k totalite. Moc je základom pre rozdeľovanie zdrojov, ale rozloženie moci v totalitnom štáte je mimoriadne nespravodlivé. Rozloženie zdrojov v totalitnom systéme bude preto tiež nespravodlivé. Vo všetkých krajinách, kde vládne alebo vládol socializmus, vzniká privilegovaná vrstva, extrémne rozdiely medzi bohatými a chudobnými a útlak ľudí zo strany štátu. Zdroje sú čerpané na vojenské účely. Majetok ľudí je rozkradnutý, aby mohla privilegovaná trieda bohatnúť a byť mocná, zatiaľ čo väčšina je ponechaná, aby pracovala v chudobe.</p>
      <p>Marxizmus podvádza ľudstvo prísľubom „každý podľa svojich schopností, každému podľa jeho potrieb“.<a href="#_edn54" id="_ednref54">[54]</a> Komunizmus klame ľudstvo sľubom, že každý člen spoločnosti bude môcť naplno rozvinúť svoje schopnosti. V socialistických ekonomikách obyčajní ľudia nemôžu konať podľa svojej vlastnej vôle, pretože nemajú základné slobody.</p>
      <p>Marxizmus hovorí, že deľba práce vytvára odcudzenie. V skutočnosti je však deľba práce pre každú spoločnosť nevyhnutná. Adam Smith argumentuje v knihe <em>Bohatstvo národov</em>, že deľba práce môže výrazne zvýšiť produktivitu a podporiť prosperitu. Rozdiely vytvorené deľbou práce nie sú nevyhnutne konfliktami, ani nemusia viesť k odcudzeniu a odosobneniu. Ľudia zo všetkých spoločenských vrstiev, bez ohľadu na ich postavenie, môžu prispievať do spoločnosti, pozdvihovať svoje mravy a prinášať ľudstvu šťastie.</p>
      <p>Komunizmus však využíva úsilie ľudí o dobro, aby ich zviedol na cestu náboženských fanatikov, oddaných komunistickej ideológii. Využíva snahu o dobro ako svoju zámienku na to, aby odtiahol ľudí od toho, čo je božské. Znečisťuje ľudskú myseľ, posilňuje zlú povahu ľudí a vedie ich k páchaniu všetkých druhov zločinov. Ľudia sa pod týmto vplyvom oddávajú materiálnemu pôžitku a vzdávajú sa vznešenejšej a ušľachtilejšej viery vo vyšší zmysel života. Komunizmus otrávi všetko, čoho sa dotkne. Za jeho vlády zahynuli milióny ľudí, ako môžeme vidieť vo všetkých krajinách, kde sa chopil moci. Ak sa ľudia vo svete teraz neprebudia, budú čeliť hrozným následkom.</p>
      <p>&nbsp;</p>
      <h2>8. Morálka, prosperita a mier</h2>
      <p>Snaha o šťastie je ľudskou prirodzenosťou. Prosperujúca ekonomika môže priniesť šťastie, ale ekonomika neexistuje vo vákuu. Keď sa cesta ekonomického rozvoja odchýli od etiky a morálky, môže nasledovať hospodárska kríza. Spoločnosť, ktorá je iba bohatá, nebude schopná prinášať radosť a šťastie, a jej prosperita bude krátkodobá. S rozpadom základných etických a morálnych hodnôt môžeme očakávať katastrofálne následky.</p>
      <p>V roku 2010 priniesol čínsky <em>Ľudový denník, </em>ktorý je hlasnou trúbou čínskeho režimu, správu, že napriek ekonomickému rozvoju Číny, index hrubého národného šťastia podľa časopisu Forbes niekoľko rokov po sebe klesal. Druhú najväčšiu ekonomiku sveta trápi korupcia, znečistenie životného prostredia a incidenty v oblasti bezpečnosti potravín, kvôli čomu sa Číňania cítia v živote extrémne neistí. V tomto prípade sa bohatstvo zvýšilo, zatiaľ čo morálka a šťastie poklesli.</p>
      <p>Tu sa odráža osudová chyba komunizmu: ľudské bytosti nie sú len telami, ale oveľa viac pozostávajú z mysle a ducha. Cesta, po ktorej sa bude život človeka uberať, je určená nebesami. Číňania hovoria, že „každé sústo a každý dúšok sú predurčené“. Obdobne v západnej duchovnej viere existuje koncept osudu a predurčenosti ľudského života. Veriaci ľudia si uvedomujú, že bohatstvo je požehnaním, ktoré im dáva Stvoriteľ. Považujú za dôležité mať pokorné a vďačné srdce, a preto sú spokojní a šťastní.</p>
      <p>Medzi tými, ktorí boli v roku 1912 na palube Titanicu, keď sa loď potopila, bol tiež milionár John Jacob Astor IV., z ktorého majetku by bolo možné postaviť tridsať Titanicov. Keď však čelil smrti, vybral si to, čo považoval za morálne správne a ochránil ženy a deti – miesto v poslednom záchrannom člne prenechal dvom vystrašeným deťom.<a href="#_edn55" id="_ednref55">[55]</a> Rovnako tak spolumajiteľ obchodného domu Macy's, Isidor Straus, vyhlásil: „Nepôjdem skôr ako ostatní muži.“ Jeho manželka Ida takisto odmietla miesto v záchrannom člne a venovala ho ich novej slúžke Ellen Birdovej. Rozhodla sa stráviť posledné chvíle so svojím manželom.<a href="#_edn56" id="_ednref56">[56]</a></p>
      <p>Títo veľmi bohatí ľudia sa rozhodli uprednostniť tradičné hodnoty a vieru pred možnosťou zachrániť svoje majetky a životy. Ich voľba morálky a spravodlivosti je prejavom vznešenosti ľudskej civilizácie a ľudskej povahy: vznešený charakter je cennejší ako život, a&nbsp;ten je cennejší ako bohatstvo.</p>
      <p>Pán Li Chung-č’, zakladateľ Falun Dafa, napísal v článku „Bohatstvo a cnosť“ :</p>
      <p><em>„Je povinnosťou vládcu a úradníkov priniesť bohatstvo obyvateľom, avšak propagovanie uctievania peňazí je najhorší postup, aký si možno predstaviť. Bohatstvo bez cnosti (de) zraní všetky vnímajúce bytosti, zatiaľ čo bohatstvo spolu s cnosťou je to, v čo dúfajú všetci ľudia. Preto sa nemožno stať bohatým bez opory v cnosti.</em>“</p>
      <p><em>„Cnosť sa hromadí v minulých životoch. Stanie sa kráľom, úradníkom, boháčom alebo šľachtou, všetko pochádza z cnosti. Žiadna cnosť, žiaden zisk; strata cnosti znamená stratu všetkého. Preto tí, ktorí hľadajú moc a&nbsp;bohatstvo, musia najprv zhromaždiť cnosť. Pretrpením ťažkostí a konaním dobrých skutkov môže človek nazhromaždiť cnosť medzi masami. Aby to dosiahol, musí človek chápať princíp príčiny a následku. Toto poznanie umožní úradníkom a obyvateľom uplatňovať sebaovládanie a blahobyt a mier potom zavládne pod nebesami.“<a href="#_edn57" id="_ednref57">[57]</a></em></p>
      <p>Pokiaľ bude ľudstvo zachovávať uvedené hodnoty týkajúce sa bohatstva a života, značne sa tým zmenšia ekonomické problémy pochádzajúce z chamtivosti, lenivosti a závisti. Len čo ľudstvo potlačí svoje sebecké túžby, ideológia komunizmu už nebude schopná zlákať ľudské srdce a morálne štandardy zostanú vysoké.</p>
      <p>Komunistický duch urobil spletité usporiadania, aby zničil ľudstvo. Jeho ekonomické plány sú len jednou časťou príbehu. Aby sme sa oslobodili od komunistických „ideálov“ a neboli nimi ďalej ovládaní, musíme odhaliť toto sprisahanie, rozpoznať jeho podvodné posolstvo a prestať vkladať nádeje do tejto skrachovanej ideológie. Musíme tiež obnoviť tradičné hodnoty a obnoviť morálku a cnosť. Ľudstvo sa tak bude môcť tešiť trvalému šťastiu a prosperite a žiť skutočne v mieri. Ľudská civilizácia bude potom žiariť s novou vitalitou.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Thomas Jefferson a kol., „United States Declaration of Independence“, 4. júla 1776, <em>National Archives,</em> stránka navštívená 20. apríla 2020, <a href="https://www.archives.gov/founding-docs/declaration-transcript"></a><a href="https://www.archives.gov/founding-docs/declaration-transcript">https://www.archives.gov/founding-docs/declaration-transcript</a>.</p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> Karl Marx a Frederick Engels, „Manifesto of the Communist Party“, in <em>Marx &amp; Engels Selected Works,</em> sv.1, prel. Samuel Moore, ed. Andy Blunden (Moscow: Progress Publishers, 1969), Marxists Internet Archive, stránka navštívená 20. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm"></a><a href="https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm">https://www.marxists.org/archive/marx/works/1848/communist-manifesto/ch04.htm</a>.</p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Fred Schwarz, <em>You Can Trust the Communists … to Be Communists</em> (New Jersey: Prentice-Hall, 1960), 26–27.</p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Friedrich A. Hayek, <em>The Fatal Conceit: The Errors of Socialism,</em> W.W. Bartley III, ed. (Chicago: University of Chicago Press, 1991).</p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Thomas Sowell, <em>Intellectuals and Society, Revised and Expanded Edition</em> (New York: Basic Books, 2012), kap. 2.</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Ludwig von Mises. „Economic Calculation in the Socialist Commonwealth“, <em>Mises Institute,</em> stránka navštívená 20. apríla 2020, <a href="https://mises.org/library/economic-calculation-socialist-commonwealth"></a><a href="https://mises.org/library/economic-calculation-socialist-commonwealth">https://mises.org/library/economic-calculation-socialist-commonwealth</a>.</p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Š‘ Šan, „Quagmire v reforme čínských štátnych podnikov“ , <em>Radio Free Asia,</em> 22. září 2015 [石山, 中国国企改革的困境, 普通话主页] <a href="https://www.rfa.org/mandarin/yataibaodao/jingmao/xql-09222015103826.html"></a><a href="https://www.rfa.org/mandarin/yataibaodao/jingmao/xql-09222015103826.html">https://www.rfa.org/mandarin/yataibaodao/jingmao/xql-09222015103826.html</a>. (v čínštine)</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Linette Lopez, „Zombie Companies Are Holding China’s Economy Hostage“, <em>Business Insider,</em> 24. máj 2016, <a href="https://www.businessinsider.com/chinas-economy-is-being-held-hostage-2016-5"></a><a href="https://www.businessinsider.com/chinas-economy-is-being-held-hostage-2016-5">https://www.businessinsider.com/chinas-economy-is-being-held-hostage-2016-5</a>.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Marx a Engels, „Manifest“.</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> Max Galka, „The History of US Government Spending, Revenue, and Debt (1790–2015)“, <em>Metrocosm,</em> 16. febrára 2016, <a href="http://metrocosm.com/history-of-us-taxes/"></a><a href="http://metrocosm.com/history-of-us-taxes/">http://metrocosm.com/history-of-us-taxes/</a>.</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> Organization for Economic Cooperation and Development, „OECD Tax Rates on Labour Income Continued Decreasing Slowly in 2016“, 4. novembra 2017, <a href="http://www.oecd.org/newsroom/oecd-tax-rates-on-labour-income-continued-decreasing-slowly-in-2016.htm"></a><a href="http://www.oecd.org/newsroom/oecd-tax-rates-on-labour-income-continued-decreasing-slowly-in-2016.htm">http://www.oecd.org/newsroom/oecd-tax-rates-on-labour-income-continued-decreasing-slowly-in-2016.htm</a>.</p>
      <p><a href="#_ednref12" id="_edn12">[12]</a> Rachel Sheffield a Robert Rector, „The War on Poverty After 50 Years“, <em>The Heritage Foundation,</em> 15. septembra 2014, <a href="https://www.heritage.org/poverty-and-inequality/report/the-war-poverty-after-50-years"></a><a href="https://www.heritage.org/poverty-and-inequality/report/the-war-poverty-after-50-years">https://www.heritage.org/poverty-and-inequality/report/the-war-poverty-after-50-years</a>.</p>
      <p><a href="#_ednref13" id="_edn13">[13]</a> Robert Rector, „The War on Poverty: 50 Years of Failure“, <em>The Heritage Foundation,</em> 23. septembra 2014, <a href="https://www.heritage.org/marriage-and-family/commentary/the-war-poverty-50-years-failure"></a><a href="https://www.heritage.org/marriage-and-family/commentary/the-war-poverty-50-years-failure">https://www.heritage.org/marriage-and-family/commentary/the-war-poverty-50-years-failure</a>.</p>
      <p><a href="#_ednref14" id="_edn14">[14]</a> Alexis de Tocqueville, <em>Memoir on Pauperism</em>, trans. Seymour Drescher (London: Civitas, 1997).</p>
      <p><a href="#_ednref15" id="_edn15">[15]</a> Sheffield a Rector, „The War on Poverty“.</p>
      <p><a href="#_ednref16" id="_edn16">[16]</a> Nima Sanandaji, <em>Scandinavian Unexceptionalism: Culture, Markets, and the Failure of Third-Way Socialism</em> (London: Institute for Economic Affairs, 2015), Kindle vydanie, 75.</p>
      <p><a href="#_ednref17" id="_edn17">[17]</a> Tocqueville, <em>Memoir</em>.</p>
      <p><a href="#_ednref18" id="_edn18">[18]</a> Na tom istom mieste, 31.</p>
      <p><a href="#_ednref19" id="_edn19">[19]</a> „A National Sport No More“, <em>The Economist,</em> 3. novembra 2012, <a href="https://www.economist.com/europe/2012/11/03/a-national-sport-no-more"></a><a href="https://www.economist.com/europe/2012/11/03/a-national-sport-no-more">https://www.economist.com/europe/2012/11/03/a-national-sport-no-more</a>.</p>
      <p><a href="#_ednref20" id="_edn20">[20]</a> Martin Halla, Mario Lackner a Friedrich G. Schneider, „An Empirical Analysis of the Dynamics of the Welfare State: The Case of Benefit Morale“, <em>Kyklos</em> 63, no.1 (2010), 55–74, <em>Wiley Online Library,</em> stránka navštívená 20. apríla 2020, <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1467-6435.2010.00460.x"></a><a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1467-6435.2010.00460.x">https://onlinelibrary.wiley.com/doi/abs/10.1111/j.1467-6435.2010.00460.x</a>.</p>
      <p><a href="#_ednref21" id="_edn21">[21]</a> Nicholas Kristof, „Profiting From a Child’s Illiteracy“, <em>The New York Times,</em> 7. decembra 2012, <a href="https://www.nytimes.com/2012/12/09/opinion/sunday/kristof-profiting-from-a-childs-illiteracy.html"></a><a href="https://www.nytimes.com/2012/12/09/opinion/sunday/kristof-profiting-from-a-childs-illiteracy.html">https://www.nytimes.com/2012/12/09/opinion/sunday/kristof-profiting-from-a-childs-illiteracy.html</a>.</p>
      <p><a href="#_ednref22" id="_edn22">[22]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref23" id="_edn23">[23]</a> Kristof, „Profiting From“.</p>
      <p><a href="#_ednref24" id="_edn24">[24]</a> William A. Niskanen, „Welfare and the Culture of Poverty“, <em>The Cato Journal</em> 16, no.1 (1996), <a href="https://www.cato.org/sites/cato.org/files/serials/files/cato-journal/1996/5/cj16n1-1.pdf"></a><a href="https://www.cato.org/sites/cato.org/files/serials/files/cato-journal/1996/5/cj16n1-1.pdf">https://www.cato.org/sites/cato.org/files/serials/files/cato-journal/1996/5/cj16n1-1.pdf</a>.</p>
      <p><a href="#_ednref25" id="_edn25">[25]</a> Walter E. Williams, „The True Black Tragedy: Illegitimacy Rate of Nearly 75%“, <em>cnsnews.com,</em> 19. mája 2015, <a href="https://www.cnsnews.com/commentary/walter-e-williams/true-black-tragedy"></a><a href="https://www.cnsnews.com/commentary/walter-e-williams/true-black-tragedy">https://www.cnsnews.com/commentary/walter-e-williams/true-black-tragedy</a>.</p>
      <p><a href="#_ednref26" id="_edn26">[26]</a> Organization for Economic Cooperation and Development, „General Government Debt (Indicator)“, 2019, stránka navštívená 27. apríla 2020, <a href="https://data.oecd.org/gga/general-government-debt.htm"></a><a href="https://data.oecd.org/gga/general-government-debt.htm">https://data.oecd.org/gga/general-government-debt.htm</a>.</p>
      <p><a href="#_ednref27" id="_edn27">[27]</a> Ronald Coase, citace dle: Thomas W. Hazlett, „Looking for Results: An Interview With Ronald Coase“, <em>Reason,</em> január 1997, <a href="https://reason.com/archives/1997/01/01/looking-for-results"></a><a href="https://reason.com/archives/1997/01/01/looking-for-results">https://reason.com/archives/1997/01/01/looking-for-results</a>.</p>
      <p><a href="#_ednref28" id="_edn28">[28]</a> Friedrich A. Hayek, <em>The Road to Serfdom</em> (Chicago: University Of Chicago Press, 1944).</p>
      <p><a href="#_ednref29" id="_edn29">[29]</a> M. Szmigiera, „Direct Investment Position of the United States in China from 2000 to 2018“, Statistica.com, 2. september 2019, <a href="https://www.statista.com/statistics/188629/united-states-direct-investments-in-china-since-2000"></a><a href="https://www.statista.com/statistics/188629/united-states-direct-investments-in-china-since-2000">https://www.statista.com/statistics/188629/united-states-direct-investments-in-china-since-2000</a>.</p>
      <p><a href="#_ednref30" id="_edn30">[30]</a> „Zhongguo waishang touzi baogao, 2016“ 中国外商投资报告, („Správa o zahraničných investiciách v Číne, 2016“), v: Zhongguo washang zhijie touzi linian gaikuang 中国外商直接投资历年概况 („Všeobecné zhrnutie priamych zahraničných investícií v&nbsp;Číne“), <em>The Ministry of Commerce of China.</em> (v čínštine)</p>
      <p><a href="#_ednref31" id="_edn31">[31]</a> U.S. Commission on the Theft of American Intellectual Property, „Update to the IP Commission Report: The Theft of American Intellectual Property: Reassessments of the Challenge and United States Policy“, (Washington DC: The National Bureau of Asian Research, február 2017), <a href="http://www.ipcommission.org/report/IP_Commission_Report_Update_2017.pdf"></a><a href="http://www.ipcommission.org/report/IP_Commission_Report_Update_2017.pdf">http://www.ipcommission.org/report/IP_Commission_Report_Update_2017.pdf</a>.</p>
      <p><a href="#_ednref32" id="_edn32">[32]</a> Chris Strohm, „No Sign China Has Stopped Hacking US Companies, Official Says“, <em>Bloomberg,</em> 18. novembra 2015, <a href="https://www.bloomberg.com/news/articles/2015-11-18/no-sign-china-has-stopped-hacking-u-s-companies-official-says"></a><a href="https://www.bloomberg.com/news/articles/2015-11-18/no-sign-china-has-stopped-hacking-u-s-companies-official-says">https://www.bloomberg.com/news/articles/2015-11-18/no-sign-china-has-stopped-hacking-u-s-companies-official-says</a>.</p>
      <p><a href="#_ednref33" id="_edn33">[33]</a> Kurt Biray, „Communist Nostalgia in Eastern Europe: Longing for the Past“, <em>Open Democracy,</em> 10. novembra 2015, <a href="https://www.opendemocracy.net/can-europe-make-it/kurt-biray/communist-nostalgia-in-eastern-europe-longing-for-past"></a><a href="https://www.opendemocracy.net/can-europe-make-it/kurt-biray/communist-nostalgia-in-eastern-europe-longing-for-past">https://www.opendemocracy.net/can-europe-make-it/kurt-biray/communist-nostalgia-in-eastern-europe-longing-for-past</a>.</p>
      <p><a href="#_ednref34" id="_edn34">[34]</a> John Polga-Hecimovich, „The Roots of Venezuela’s Failing State“, <em>Origins</em> 10, no. 9 (jún 2017), <a href="http://origins.osu.edu/article/roots-venezuelas-failing-state"></a><a href="http://origins.osu.edu/article/roots-venezuelas-failing-state">http://origins.osu.edu/article/roots-venezuelas-failing-state</a>.</p>
      <p><a href="#_ednref35" id="_edn35">[35]</a> José Niño, „Venezuela Before Chavez: A Prelude to Socialist Failure“, <em>Mises Wire,</em> 4. máj 2017, <a href="https://mises.org/wire/venezuela-chavez-prelude-socialist-failure"></a><a href="https://mises.org/wire/venezuela-chavez-prelude-socialist-failure">https://mises.org/wire/venezuela-chavez-prelude-socialist-failure</a>.</p>
      <p><a href="#_ednref36" id="_edn36">[36]</a> John Bissett, „Hugo Chavez: Revolutionary Socialist or Leftwing Reformist?“ <em>Socialist Standard</em> 101, no. 1215 (november 2005), <a href="http://socialiststandardmyspace.blogspot.com/2015/05/hugo-chavez-revolutionary-socialist-or.html"></a><a href="http://socialiststandardmyspace.blogspot.com/2015/05/hugo-chavez-revolutionary-socialist-or.html">http://socialiststandardmyspace.blogspot.com/2015/05/hugo-chavez-revolutionary-socialist-or.html</a>.</p>
      <p><a href="#_ednref37" id="_edn37">[37]</a> Julian Adorney, „Socialism Set Fire to Venezuela’s Oil Crisis“, <em>Real Clear World,</em> 29. august 2017, <a href="https://www.realclearworld.com/articles/2017/08/29/socialism_set_fire_to_venezuelas_oil_crisis_112520.html"></a><a href="https://www.realclearworld.com/articles/2017/08/29/socialism_set_fire_to_venezuelas_oil_crisis_112520.html">https://www.realclearworld.com/articles/2017/08/29/socialism_set_fire_to_venezuelas_oil_crisis_112520.html</a>.</p>
      <p><a href="#_ednref38" id="_edn38">[38]</a> José Niño, „John Oliver is Wrong About Venezuela — It’s a Socialist Country“, <em>Mises Institute,</em> 30. máj 2018, <a href="https://mises.org/wire/john-oliver-wrong-about-venezuela-—-its-socialist-country"></a><a href="https://mises.org/wire/john-oliver-wrong-about-venezuela-%E2%80%94-its-socialist-country">https://mises.org/wire/john-oliver-wrong-about-venezuela-%E2%80%94-its-socialist-country</a>.</p>
      <p><a href="#_ednref39" id="_edn39">[39]</a> Chris McGreal, „Zimbabwe’s Inflation Rate Surges to 231,000,000%“, <em>The Guardian,</em> 9. október 2008, <a href="https://www.theguardian.com/world/2008/oct/09/zimbabwe"></a><a href="https://www.theguardian.com/world/2008/oct/09/zimbabwe">https://www.theguardian.com/world/2008/oct/09/zimbabwe</a>.</p>
      <p><a href="#_ednref40" id="_edn40">[40]</a> Jason Long, „The Surprising Social Mobility of Victorian Britain“,” <em>European Review of Economic History</em> 17, no. 1 (1. február 2013): 1–23, <a href="https://doi.org/10.1093/ereh/hes020"></a><a href="https://doi.org/10.1093/ereh/hes020">https://doi.org/10.1093/ereh/hes020</a>.</p>
      <p><a href="#_ednref41" id="_edn41">[41]</a> Michael Rothschild, <em>Bionomics: Economy as Ecosystem</em> (Washington, DC: Beard Books, 2004), 115.</p>
      <p><a href="#_ednref42" id="_edn42">[42]</a> Adam Smith, <em>The Theory of Moral Sentiments</em> (Malta: Gutenberg Publishers, 2011).</p>
      <p><a href="#_ednref43" id="_edn43">[43]</a> Lawrence Kudlow, <em>American Abundance: The New Economic and Moral Prosperity</em> (New York: HarperCollins Publishers, 1997).</p>
      <p><a href="#_ednref44" id="_edn44">[44]</a> Thomas Sowell, <em>Economic Facts and Fallacies</em> (New York: Basic Books, 2008), 174.</p>
      <p><a href="#_ednref45" id="_edn45">[45]</a> Friedrich Engels, „Trades Unions“, The Labour Standard, 28. máj 1881, <em>Marxists Internet Archive,</em> stránka navštívená 20. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1881/05/28.htm"></a><a href="https://www.marxists.org/archive/marx/works/1881/05/28.htm">https://www.marxists.org/archive/marx/works/1881/05/28.htm</a>.</p>
      <p><a href="#_ednref46" id="_edn46">[46]</a> Vladimir Lenin, „The Trade Unions, The Present Situation and Trotsky’s Mistakes“, in: <em>Lenin’s Collected Works,</em> prel. Yuri Sdobnikov (Moscow: Progress Publishers, 1965), 32:19.</p>
      <p><a href="#_ednref47" id="_edn47">[47]</a> Lü Ťia-min 呂嘉民, „Liening gonghuixueshuo shi“ 列寧工會學說史 („História leninistickej odborovej teórie“), (Liaoning People’s Press, 1987). (v čínštine)</p>
      <p><a href="#_ednref48" id="_edn48">[48]</a> James Sherk, „What Unions Do: How Labor Unions Affect Jobs and the Economy“, <em>The Heritage Foundation,</em> 21. máj 2009, <a href="https://www.heritage.org/jobs-and-labor/report/what-unions-do-how-labor-unions-affect-jobs-and-the-economy"></a><a href="https://www.heritage.org/jobs-and-labor/report/what-unions-do-how-labor-unions-affect-jobs-and-the-economy">https://www.heritage.org/jobs-and-labor/report/what-unions-do-how-labor-unions-affect-jobs-and-the-economy</a>.</p>
      <p><a href="#_ednref49" id="_edn49">[49]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref50" id="_edn50">[50]</a> Edwin J. Feulner, „Taking Down Twinkies“, <em>The Heritage Foundation,</em> 19. november 2012, <a href="https://www.heritage.org/jobs-and-labor/commentary/taking-down-twinkies"></a><a href="https://www.heritage.org/jobs-and-labor/commentary/taking-down-twinkies">https://www.heritage.org/jobs-and-labor/commentary/taking-down-twinkies</a>.</p>
      <p><a href="#_ednref51" id="_edn51">[51]</a> Sherk, „What Unions Do“.</p>
      <p><a href="#_ednref52" id="_edn52">[52]</a> Na tom istom mieste.</p>
      <p><a href="#_ednref53" id="_edn53">[53]</a> Steve Inskeep, „Solidarity for Sale: Corruption in Labor Unions“, <em>National Public Radio,</em> 6. február 2007, <a href="https://www.npr.org/templates/story/story.php?storyId=5181842"></a><a href="https://www.npr.org/templates/story/story.php?storyId=5181842">https://www.npr.org/templates/story/story.php?storyId=5181842</a>.</p>
      <p><a href="#_ednref54" id="_edn54">[54]</a> Karl Marx, „Critique of the Gotha Programme“, in: <em>Marx &amp; Engels Selected Works</em> (Moscow: Progress Publishers, 1970), 3:13–30, cez: Marxists Internet Archive, stránka navštívená 20. apríla 2020, <a href="https://www.marxists.org/archive/marx/works/1875/gotha/ch01.htm"></a><a href="https://www.marxists.org/archive/marx/works/1875/gotha/ch01.htm">https://www.marxists.org/archive/marx/works/1875/gotha/ch01.htm</a>.</p>
      <p><a href="#_ednref55" id="_edn55">[55]</a> Children on the Titanic. Directed by Keith Wootton. Marina Del Rey, CA: Vision Films, 2014.</p>
      <p><a href="#_ednref56" id="_edn56">[56]</a> Isidor Straus, <em>The Autobiography of Isidor Straus</em> (Smithtown, NY: The Straus Historical Society, 2011), 168–176.</p>
      <p><a href="#_ednref57" id="_edn57">[57]</a> Li Chung-č’, „Bohatstvo a cnosť“, v: Základy pre ďalší pokrok, 27. január 1995, <a href="https://sk.falundafa.org/knihy/jingwen/1995-2001/jjyz_sk.html"></a><a href="https://sk.falundafa.org/knihy/jingwen/1995-2001/jjyz_sk.html">https://sk.falundafa.org/knihy/jingwen/1995-2001/jjyz_sk.html</a>.</p>

      <Link to={`/kapitola_10`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


